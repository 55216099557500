import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import Lightbox from "react-image-lightbox";
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import {
  IMG01,
  IMG02,
  IMG03,
  IMG04,
  IMG05,
  IMG_sp_02,
  IMG_sp_03,
  IMG_sp_04,
  IMG_sp_05,
  IMG_fe_01,
  IMG_fe_02,
  IMG_fe_03,
  IMG_fe_04
} from "./img";

import {get_all_package_req} from "../../../../../redux-services/actions/action.packages/action.function.js";
import img1 from "../../../../../assets/images/features/feature-01.jpg";
import img2 from "../../../../../assets/images/features/feature-02.jpg";
import img3 from "../../../../../assets/images/features/feature-03.jpg";
import img4 from "../../../../../assets/images/features/feature-04.jpg";
import packagesImage from "../../../../../assets/images/packages.png";
import {add_to_cart_request,delete_from_cart_request} from "../../../../../redux-services/actions/action.cart/action.function.js"

const images = [img1, img2, img3, img4];
function SearchList(props) {
  const [photoIndex, setPhotIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [cartVar, setCartVar] = useState([]);
  const [popularpackageData, setPopularPackageData] = useState([]);
  const [popularPackageCartFilterVar, setPopularPackageCartFilterVar] = useState([]);

  // const getAllPakagesApi = () =>{
  //   const {get_all_packages} = props;
  //   get_all_packages();
  //   const all_packages = props.all_packages;
  //   // console.log("gen_packages =====", all_packages);
  // }

  const cartValueGreaterCheck = () =>{
    let selectCartVar = new Array(props.all_packages?.response.length).fill(false);
    props.cart.map((data,idx) =>{
      let indexCartValue = props.all_packages?.response.findIndex((packages) =>{
                        return packages.package_id === data.package_id
                  })

                  console.log("index includes values packages", indexCartValue);

                  if(indexCartValue == -1){
                    setCartVar(selectCartVar);
                  } 
                  else{
                    let cartIncludeIn = props.all_packages?.response.some(function(packages) {
                      console.log("cart ids ===", data.package_id);
                      console.log("package ids ===", packages.package_id);
                          return packages.package_id === data.package_id
                          
                      });

   if(cartIncludeIn == true){
  
      selectCartVar[indexCartValue] = true
       setCartVar(selectCartVar);

  }

                  }
      
        

  })


  // popular Popular cart val


  let selectPopularPackageCartVar = new Array(popularpackageData.length).fill(false);
    props.cart.map((data,idx) =>{
      let indexPopularPackageCartValue = popularpackageData.findIndex((packages) =>{
                        return packages.package_id === data.package_id
                  })

                  console.log("index PopularPackageCart includes values ", indexPopularPackageCartValue);

                  if(indexPopularPackageCartValue == -1){
                    setPopularPackageCartFilterVar(selectPopularPackageCartVar);
                  } 
                  else{
                    let cartPopularPackageIncludeIn = popularpackageData.some(function(packages) {
                      console.log("cart ids ===", data.package_id);
                      console.log("package ids ===", packages.package_id);
                          return packages.package_id === data.package_id
                          
                      });

   if(cartPopularPackageIncludeIn == true){
  
      selectPopularPackageCartVar[indexPopularPackageCartValue] = true;
      setPopularPackageCartFilterVar(selectPopularPackageCartVar);

  }

                  }
      
        

  })


  }

  

  const getAllPakagesApi = async() =>{
    // const {get_all_packages} = props;
    // get_all_packages();
    // const all_packages = props.all_packages;
    // console.log("gen_packages =====", all_packages);
    await props.get_all_packages(null,(error,success)=>{
      if(error){
        
        console.log("error",error);
      }    
      if(success.status == 200 ){
        if((success && success?.response.length > 0)){
          let poularTestDataValues = success?.response.filter((data,idx) =>{return (data.popular_package == 1)}
          )
          setPopularPackageData(poularTestDataValues);
          if(props.cart.length > 0){
            cartValueGreaterCheck();
    } else{
      let selectCartVar = new Array(success?.response.length).fill(false);
      let selectPopularPackageCartVar = new Array(poularTestDataValues.length).fill(false);
     
    
      setCartVar(selectCartVar);
      setPopularPackageCartFilterVar(selectPopularPackageCartVar);
    }
    
         }
      }
    })
  }
  console.log("popular package data ==",popularpackageData);
 
  useEffect(() => {
     getAllPakagesApi();
     
  }, []);

  useEffect(() =>{
    if((props.all_packages && (props.all_packages?.response.length > 0))){
     
      if(props.cart.length > 0){
        cartValueGreaterCheck();
} else{
  let selectCartVar = new Array(props.all_packages?.response.length).fill(false);

  setCartVar(selectCartVar);

    // popular test data
    let selectPopularPackageCartVar = new Array(popularpackageData.length).fill(false);
    setPopularPackageCartFilterVar(selectPopularPackageCartVar);
}

     }
  },[props.cart]);
  useEffect(() =>{
    if((props.popularPackageFilter == true) && (popularpackageData.length != 0)){
      let selectPopularPackageCartVar = new Array(popularpackageData.length).fill(false);
    props.cart.map((data,idx) =>{
      let indexPopularPackageCartValue = popularpackageData.findIndex((packages) =>{
                        return packages.package_id === data.package_id
                  })

                  console.log("index PopularPackageCart includes values ", indexPopularPackageCartValue);

                  if(indexPopularPackageCartValue == -1){
                    setPopularPackageCartFilterVar(selectPopularPackageCartVar);
                  } 
                  else{
                    let cartPopularPackageIncludeIn = popularpackageData.some(function(packages) {
                      console.log("cart ids ===", data.package_id);
                      console.log("package ids ===", packages.package_id);
                          return packages.package_id === data.package_id
                          
                      });

   if(cartPopularPackageIncludeIn == true){
  
      selectPopularPackageCartVar[indexPopularPackageCartValue] = true
      setPopularPackageCartFilterVar(selectPopularPackageCartVar);

  }

                  }
      
        

  })
    }
  },[props.popularPackageFilter == true])

  console.log("popularPackageCartFilterVar  ====",popularPackageCartFilterVar);

  const handleCart = async(data,id, dataGrp) =>{
    // popularPackageCartFilterVar
    if(dataGrp == "popularPackage"){
      if(popularPackageCartFilterVar[id] == false){
        await props.add_to_cart(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            } 
            if(success){
                console.log("success",success);
               let newPopularPackageCartFilterVar = [...popularPackageCartFilterVar];
               newPopularPackageCartFilterVar[id] = true;
               setPopularPackageCartFilterVar(newPopularPackageCartFilterVar);
            }
        })
    }
    else{
       await props.delete_from_cart(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            } 
            if(success){
                console.log("success",success);
                let newPopularPackageCartFilterVar = [...popularPackageCartFilterVar];
                newPopularPackageCartFilterVar[id] = false;
                setPopularPackageCartFilterVar(newPopularPackageCartFilterVar);
            }
        })
    }
    }

    if(dataGrp == "allPackages"){
      if(cartVar[id] == false){
        await props.add_to_cart(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            } 
            if(success){
                console.log("success",success);
               let newCartVar = [...cartVar];
               newCartVar[id] = true;
               setCartVar(newCartVar);
            }
        })
    }
    else{
       await props.delete_from_cart(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            } 
            if(success){
                console.log("success",success);
                let newCartVar = [...cartVar];
                newCartVar[id] = false;
                setCartVar(newCartVar);
            }
        })
    }
    }

   
    
}
console.log("cart val=========",cartVar);

const history = useHistory();
const nextPath = (path,data) => {
  history.push({
    pathname: path,
    state:data
  });
};
const handle_details = (data) =>{
  nextPath(`/home/allPackagesView/packageDetails`,data);
}

  return (
    <div className="content doctor-grid">
      <div className="container-fluid">
      <div className="row">
      {
        ((props.all_packages?.response.length > 0)&& (props.popularPackageFilter == true))?(
          <>
              

             { (popularpackageData.length > 0)?(popularpackageData.map((packages,idx) => (
             
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 package-card" key={idx}>
                <div className="profile-widget">
                  <div className="doc-img">
                    <div onClick={() => handle_details(packages)}>
                      <img className="img-fluid" alt="packages" src={packagesImage} />
                    </div>
                    {/* <a href="#0" className="fav-btn">
                      <i className="far fa-bookmark"></i>
                    </a> */}
                  </div>
                  <div className="pro-content">
                    <h3 className="title text-center" onClick={() => handle_details(packages)}>
                      {/* <div onClick={() => handle_details(packages)}> */}
                        {packages.package_name != null
                          ? packages.package_name
                          : "None"}
                      {/* </div> */}
                      {/* <i className="fas fa-check-circle verified"></i> */}
                    </h3>
                    {/* <p className="speciality">
                     
                        {(packages.short_description != null)? ((packages.short_description.length > 30)?`${packages.short_description.substring(0,30)}...`:packages.short_description):"None"}
                    </p> */}
                   
                    <ul className="available-info clini-infoss">
                      <li className="text-center">
                        {/* <i className="far fa-money-bill-alt"></i>{" "} */}
                        {packages.package_cost != null
                          ? `₹ ${packages.package_cost}`
                          : "None"}{" "}
                        {/* <i
                          className="fas fa-info-circle"
                          data-toggle="tooltip"
                          title="Package Cost"
                        ></i> */}
                      </li>
                    </ul>
                    <div className="row row-sm">
                      <div className="col-6">
                        <button 
                    onClick={() => handle_details(packages)} 
                    type="button" 
                          className="btn btn-detail"
                        >
                         Know More
                        </button>
                      </div>
                      <div className="col-6">
                       

                        
                        
                        <button
                         onClick={() =>handleCart(packages,idx,"popularPackage")} 
                        //  className="apt-btn"
                        type="button"
                            className="btn btn-cart "
                         >{(popularPackageCartFilterVar[idx] == false) ? `Add to Cart` : `Remove`}</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
             

              ))):(
                <div className="col-sm-6 col-md-4 col-xl-6">
              <h3><i>Popular packages yet to be added</i></h3>
            </div>
              )}
            </>
        ):(
          <>
          {props.all_packages && props.all_packages?.response.length > 0 ? (
            props.all_packages?.response.map((packages, idx) => (
              <>
             {(props.searchFilterData == null)?(
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 package-card" key={idx}>
                <div className="profile-widget">
                  <div className="doc-img">
                    <div onClick={() => handle_details(packages)}>
                      <img className="img-fluid" alt="packages" src={packagesImage} />
                    </div>
                    {/* <a href="#0" className="fav-btn">
                      <i className="far fa-bookmark"></i>
                    </a> */}
                  </div>
                  <div className="pro-content">
                    <h3 className="title text-center" onClick={() => handle_details(packages)}>
                      {/* <div onClick={() => handle_details(packages)}> */}
                        {packages.package_name != null
                          ? packages.package_name
                          : "None"}
                      {/* </div> */}
                      {/* <i className="fas fa-check-circle verified"></i> */}
                    </h3>
                    {/* <p className="speciality">
                     
                        {(packages.short_description != null)? ((packages.short_description.length > 30)?`${packages.short_description.substring(0,30)}...`:packages.short_description):"None"}
                    </p> */}
                   
                    <ul className="available-info clini-infoss">
                      <li className="text-center">
                        {/* <i className="far fa-money-bill-alt"></i>{" "} */}
                        {packages.package_cost != null
                          ? `₹ ${packages.package_cost}`
                          : "None"}{" "}
                        {/* <i
                          className="fas fa-info-circle"
                          data-toggle="tooltip"
                          title="Package Cost"
                        ></i> */}
                      </li>
                    </ul>
                    <div className="row row-sm">
                      <div className="col-6">
                        <button 
                    onClick={() => handle_details(packages)} 
                    type="button" 
                          className="btn btn-detail"
                        >
                          know more
                        </button>
                      </div>
                      <div className="col-6">
                       

                        
                        
                        <button
                         onClick={() =>handleCart(packages,idx,"allPackages")} 
                        //  className="apt-btn"
                        type="button"
                            className="btn btn-cart"
                         >{(cartVar[idx] == false) ? `Add to Cart` : `Remove`}</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ):(packages.package_name.toLowerCase().includes(props.searchFilterData.toLowerCase()))?(
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 package-card" key={idx}>
                <div className="profile-widget">
                  <div className="doc-img">
                    <div onClick={() => handle_details(packages)}>
                      <img className="img-fluid" alt="packages" src={packagesImage} />
                    </div>
                    {/* <a href="#0" className="fav-btn">
                      <i className="far fa-bookmark"></i>
                    </a> */}
                  </div>
                  <div className="pro-content">
                    <h3 className="title text-center" onClick={() => handle_details(packages)}>
                      {/* <div onClick={() => handle_details(packages)}> */}
                        {packages.package_name != null
                          ? packages.package_name
                          : "None"}
                      {/* </div> */}
                      {/* <i className="fas fa-check-circle verified"></i> */}
                    </h3>
                    {/* <p className="speciality">
                    
                        {(packages.short_description != null)? ((packages.short_description.length > 30)?`${packages.short_description.substring(0,30)}...`:packages.short_description):"None"}
                    </p> */}
                    
                    <ul className="available-info clini-infoss">
                      <li className="text-center">
                        {/* <i className="far fa-money-bill-alt"></i>{" "} */}
                        {packages.package_cost != null
                          ? `₹ ${packages.package_cost}`
                          : "None"}{" "}
                        {/* <i
                          className="fas fa-info-circle"
                          data-toggle="tooltip"
                          title="Package Cost"
                        ></i> */}
                      </li>
                    </ul>
                    <div className="row row-sm">
                      <div className="col-6">
                        <button 
                    onClick={() => handle_details(packages)} 
                    type="button" 
                          className="btn btn-detail"
                        >
                          View Details
                        </button>
                      </div>
                      <div className="col-6">
                    

                        
                        
                        <button
                         onClick={() =>handleCart(packages,idx,"allPackages")} 
                        //  className="apt-btn"
                        type="button"
                            className="btn btn-cart"
                         >{(cartVar[idx] == false) ? `Add to Cart` : `Remove`}</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>):
              null
             }
             </>
            ))
          ) : (
            <div className="col-sm-6 col-md-4 col-xl-6">
              <h3><i>Packages yet to be added</i></h3>
            </div>
          )}
        </>
        )
      }
      </div>
       
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    // get_all_packages: () => dispatch(get_all_package_req()),
    get_all_packages: (data,callback) => dispatch(get_all_package_req(data,callback)),
    add_to_cart: (data,callback) =>dispatch(add_to_cart_request(data,callback)),
        delete_from_cart: (data,callback) =>dispatch(delete_from_cart_request(data,callback)),
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    all_packages: state.packages.all_packages,
    user: state.user.user,
    cart:state.cart.cartVal,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
