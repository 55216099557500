export const selectArea = [ 
      "Basavanagudi",
      "Malleshvaram",
      "Girinagar",
      "SBM Colony"];
    
export const selectCity = [ 
        "Bengaluru",
        "Shivmogga",
        "Mangaluru",
        "Mysore"];

export const selectIdCardType = [ 
            "Adhar Card",
            "Voter Id Card",
            "Pan Card"];


export const CONSTANAT_MESSAGES ={
              
      // COUPON_VALIDATION
      // MAX_EXCEED:`maxuser has already exceed`,
      MAX_EXCEED:`Maximum users have been validated this coupan`,
      MIN_AMT_RQRED:`minimum amount required for apply the coupon`,
      COUPON_EXPIRED:`coupon expired`,
      COUPON_SUCCESS:`coupon validate successfully`,
      

}

export const paymentMethod = [
      "Online Payment",
      "Cash On Delivery (COD)"
];

export const paymentMethodCancellApply = [
      "Online Payment"
];

export const statusConstants ={
      raised:"raised",
      processed:"processed",
      closed:"closed",
      cancelled:"cancelled",
      declined:"declined",
       assigned:"assigned"
};

export const statusTicketConstants ={
      raised:"raised",
      processing:"processing",
      resolved:"resolved",
}

export const uploadFileTypeConstants = {
      url:"URL", 
      blog:"Blog", 
      uploadFile:"Upload file",
      video:"Video",
}

export const circleRadius = 5;
export const circleRadiusInMeters = 5000;

