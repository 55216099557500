import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from "history";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance';
import IMG01 from '../../../../assets/images/patients/avatar.png';
import './navlinkStyle.css';
import { logout_req } from "../../../../redux-services/actions/action.users/action.function";
import { logout_request } from "../../../../redux-services/actions/action.logout/action.function";
import { API_URL } from '../../../../redux-services/api/api.js';
import { API } from '../../../../utils/networkApi.js';
import { persistor } from '../../../../redux-services/store/create.store.js';
import { toast } from "react-toastify"
export const DashboardSidebar = (props) => {
	const history = createBrowserHistory();
	let pathnames = window.location.pathname;
	const url = pathnames.split("/").slice(2).join("/");
	// const onHandleMobileMenu = () => {
	// 	var root = document.getElementsByTagName("html")[0];
	// 	root.classList.add("menu-opened");
	//   };

	//   const onhandleCloseMenu = () => {
	// 	var root = document.getElementsByTagName("html")[0];
	// 	root.classList.remove("menu-opened");
	//   };


	//   useEffect(() => {
	// 	$(".main-nav a").on("click", function (e) {
	// 	  if ($(this).parent().hasClass("has-submenu")) {
	// 		e.preventDefault();
	// 	  }
	// 	  if (!$(this).hasClass("submenu")) {
	// 		$("ul", $(this).parents("ul:first")).slideUp(350);
	// 		$("a", $(this).parents("ul:first")).removeClass("submenu");
	// 		$(this).next("ul").slideDown(350);
	// 		$(this).addClass("submenu");
	// 	  } else if ($(this).hasClass("submenu")) {
	// 		$(this).removeClass("submenu");
	// 		$(this).next("ul").slideUp(350);
	// 	  }
	// 	});
	//   }, []);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [photo, setPhoto] = useState(null);
	const dateValue = moment(new Date()).format("YYYY-MM-DD");
	const [dateOfBirth, setDateOfBirth] = useState(dateValue);
	const [gender, setGender] = useState("");
	const [dateOfBirthStatus, setDateOfBirthStatus] = useState(false);




	const DateConversion = (data) => {

		if (data == null) {
			setDateOfBirthStatus(true);
		}
		const DateObtained = moment(data).format("DD-MM-YYYY");
		// console.log("age", DateObtained);
		const AgeValue = moment().diff(moment(`${DateObtained}`, "DD-MM-YYYY"), "years", false);

		setDateOfBirth(AgeValue);

	}


	useEffect(() => {
		console.log("props.user_by_id.photo", (props.user_by_id) || (props.user_by_id.photo) || (props.user_by_id.photo == "undefined"))
		console.log("props.user_by_id.photo@@", props.user_by_id.photo)
		console.log("props.user_by_id.photo!!!", props.user_by_id.length)
		console.log("props.user_by_id.photo##", (props.user_by_id))

		setFirstName((props.user_by_id && (props.user_by_id.length != 0) && props.user_by_id.first_name));
		setLastName((props.user_by_id && (props.user_by_id.length != 0) && props.user_by_id.last_name));
		setPhoto((props.user_by_id && (props.user_by_id.length != 0) && props.user_by_id.photo));
		// setPhoto(props.user_by_id.photo);

		setEmail((props.user_by_id && (props.user_by_id.length != 0) && props.user_by_id.email));
		DateConversion((props.user_by_id && (props.user_by_id.length != 0) && props.user_by_id.date_of_birth));

		if ((props.user_by_id && (props.user_by_id.length != 0) && props.user_by_id.gender)) {
			if ((props?.user_by_id?.gender == "male") || props?.user_by_id?.gender == "Male") {
				setGender("Male");
			}
			else if ((props?.user_by_id?.gender == "female") || (props?.user_by_id?.gender == "Female")) {
				setGender("Female");
			}


		}



	}, [props.user_by_id])
	console.log("url from side bar", url);

	const verify_logout = async () => {
		if (props.user?.userId != null) {

		

			const result = await API.get(API_URL.VERIFY_LOGOUT, API.getHeaders());
			let response = await result.data;
			console.log("response values of logout api ", response);
			if (response.status === 200) {
				//  toaster('success', response.message);
				//   props.logout_req();
				localStorage.removeItem('token');
				localStorage.clear();
				persistor.pause();
				persistor.flush().then(() => {
					return persistor.purge();
				});
				history.push("/home/login");
				window.location.reload();
				toast.success("Log out successful", {
					position: toast.POSITION.BOTTOM_RIGHT
				})

			}
		}

	}

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const menuItems = [
		{ path: "dashboard", name: "Dashboard", link: "/users/dashboard", icon: "fas fa-columns" },
		{ path: "familyMembers", name: "Family Members", link: "/dashboard/familyMembers", icon: "fas fa-bookmark" },
		{ path: "myAddress", name: "My Address", link: "/dashboard/myAddress", icon: "fas fa-address-book" },
		{ path: "Orders", name: "My Orders", link: "/dashboard/Orders", icon: "fas fa-border-all" },
		{ path: "Reports", name: "Reports", link: "/dashboard/Reports", icon: "fas fa-toolbox" },
		{ path: "Support", name: "Support", link: "/dashboard/Support", icon: "fas fa-tools" },
		{ path: "Feedback", name: "Feedback", link: "/dashboard/Feedback", icon: "fas fa-tools" },
		{ path: "profile", name: "Profile Settings", link: "/dashboard/profile", icon: "fas fa-user-cog" },
		{ path: "change-mpin", name: "Change MPIN", link: "/dashboard/change-mpin", icon: "fas fa-lock" },
	];

	const activeMenuItem = menuItems.find(item => url.includes(item.path))?.name || "";

	return (
		<div className="profile-sidebar">
			<Link to="/dashboard/profile" >
				<div className="widget-profile pro-widget-content">
					<div className="profile-info-widget">
						<a href="#0" className="booking-doc-img">
							<img
								src={(photo == "false" || photo == false || photo == null || photo == "null") ? IMG01 : `${API_URL_ROOT}/${photo}`}
								// src={IMG01} 
								alt="User" />
							{console.log("photo value is", photo)}
						</a>
						<div className="profile-det-info">
							<h3>{((firstName != null) && (lastName != null)) ? `${firstName} ${lastName}` : "User name"}</h3>
							<div className="patient-details">
								{/* <h5><i className="fas fa-id-badge"></i>1WS140003</h5> */}
								<h5><i className="fas fa-venus-mars"></i> {(gender != null) ? gender : "None"}</h5>
								{(dateOfBirthStatus == true) ? null : (<h5 className="mb-0"><i className="fas fa-birthday-cake"></i> {(dateOfBirth != null) ? dateOfBirth : null} years</h5>)}

							</div>
						</div>
					</div>
				</div>
			</Link>
			<div className="dashboard-widget">
				<nav className="dashboard-menu">
					{/* Toggle Button for Mobile */}
					<button className="menu-toggle" onClick={toggleMenu}>
						{isMenuOpen ? (
							'❌'
						) : (
							<>
								<div className="menu-icon">
								<div className="dot"></div>
    <div className="dot"></div>
		<div className="dot"></div>
    <div className="dot"></div>
   
		
								</div>
								<h4 >{activeMenuItem}</h4>
							</>
						)}
					</button>


					{/* Navigation Menu */}
					<ul className={isMenuOpen ? "menu open" : "menu"}>
						<li className={url.includes("dashboard") ? "active" : ""}>
							<Link to="/users/dashboard">
								<i className="fas fa-columns"></i>
								<span className="dashboard-menu-span">Dashboard</span>
							</Link>
						</li>
						<li className={url.includes("familyMembers") ? "active" : ""}>
							<Link to="/dashboard/familyMembers">
								<i className="fas fa-bookmark"></i>
								<span className="dashboard-menu-span">Family Members</span>
							</Link>
						</li>
						<li className={url.includes("myAddress") ? "active" : ""}>
							<Link to="/dashboard/myAddress">
								<i className="fas fa-address-book"></i>
								<span className="dashboard-menu-span">My Address</span>
							</Link>
						</li>
						<li className={url.includes("Orders") ? "active" : ""}>
							<Link to="/dashboard/Orders">
								<i className="fas fa-border-all"></i>
								<span className="dashboard-menu-span">My Orders</span>
							</Link>
						</li>
						<li className={url.includes("Reports") ? "active" : ""}>
							<Link to="/dashboard/Reports">
								<i className="fas fa-toolbox"></i>
								<span className="dashboard-menu-span">Reports</span>
							</Link>
						</li>
						<li className={url.includes("Support") ? "active" : ""}>
							<Link to="/dashboard/Support">
								<i className="fas fa-tools"></i>
								<span className="dashboard-menu-span">Support</span>
							</Link>
						</li>
						<li className={url.includes("Feedback") ? "active" : ""}>
							<Link to="/dashboard/Feedback">
								<i className="fas fa-tools"></i>
								<span className="dashboard-menu-span">Feedback</span>
							</Link>
						</li>
						<li className={url.includes("profile") ? "active" : ""}>
							<Link to="/dashboard/profile">
								<i className="fas fa-user-cog"></i>
								<span className="dashboard-menu-span">Profile Settings</span>
							</Link>
						</li>
						<li className={url.includes("change-mpin") ? "active" : ""}>
							<Link to="/dashboard/change-mpin">
								<i className="fas fa-lock"></i>
								<span className="dashboard-menu-span">Change MPIN</span>
							</Link>
						</li>
						<li>
							<Link>
								<i className="fas fa-sign-out-alt"></i>
								<span
									className="dashboard-menu-span"
									onClick={() => verify_logout()}
								>
									Logout
								</span>
							</Link>
						</li>
					</ul>
				</nav>
			</div>

		</div>
	);
}
const mapDispatchToProps = (dispatch) => {
	return {
		logout_req: () => dispatch(logout_req()),
		user_logout_req: (data, callback) => dispatch(logout_request(data, callback)),
		//  user_by_id_req: (data,callback) =>dispatch(get_user_by_id_req(data,callback)),
	};
};
const mapStateToProps = (state) => {
	return {
		status: state.user.status,
		error: state.user.error,
		user_by_id: state.user.user_by_id,
		user: state.user.user,
		isLoggedIn: state.user.isLoggedIn,
		requesting: state.user.requesting,


	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
