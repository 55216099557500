import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { createBrowserHistory } from "history";
import './myStyles.css'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// //icon

// import { faHospital } from "@fortawesome/free-regular-svg-icons";
import logo from "../assets/images/logo.png";
import userIcon from "../assets/images/userIcon.png"
// import logoicon from "../assets/images/";
import logoPatch from "../assets/images/footer-logo.png";
import IMG01 from "../assets/images/doctors/doctor-thumb-02.jpg";
import avatar from "../assets/images/doctors/doctor-thumb-01.jpg";
import { API_URL_ROOT } from "../redux-services/actions/constance/action.constance";
import CustomNotificationHeader from "../utils/CustomNotificationHeader";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
// import { useEffect } from "react";
import { logout_req, get_user_by_id_req } from "../redux-services/actions/action.users/action.function";
import { logout_request } from "../redux-services/actions/action.logout/action.function";

import { get_all_custom_notification_req, post_custom_notification_read_status_by_id_request } from "../redux-services/actions/action.customnotification/action.function";
import toaster from "../utils/ReactTostify";
import { API_URL } from '../redux-services/api/api.js';
import { API } from '../utils/networkApi.js';
import playstore from "../assets/images/playstore.png";
import appstore from "../assets/images/appstore.png";
import Sidebar from "./home/sidebar/index.jsx";





const Header = (props) => {
  let pathnames = window.location.pathname

  const [active, setActive] = useState(false);
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState(null);


  // notification data state
  const [customNotificationData, setCustomNotificationData] = useState([]);



  const urlHome = pathnames.split("/").slice(1);
  const url = pathnames.split("/").slice(1).join("/");
  const urlCustom = pathnames.split("/").slice(3).join("/");
  

  const onHandleMobileMenu = () => {

    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };
  const nextPathDataPush = (path, data) => {
    console.log("hi@@###")
    props.history.push({
      pathname: path, state: {
        data: data,
      }
    })
  }

  useEffect(() => {
    $(".main-nav a").on("click", function (e) {
      if ($(this).parent().hasClass("has-submenu")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("submenu")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("submenu");
        $(this).next("ul").slideDown(350);
        $(this).addClass("submenu");
      } else if ($(this).hasClass("submenu")) {
        $(this).removeClass("submenu");
        $(this).next("ul").slideUp(350);
      }
    });
  }, []);

  const handleCartClick = () => {
    const history = createBrowserHistory();
    history.push("/users/addToCart");
  };
const verify_logout= async() =>{
  if(props.user?.userId != null){
    
  //  await props.user_logout_req(null,(error,success)=>{
  //     if(error){
        
  //       console.log("error",error); 
  //     } 
    
  //     if(success){
    
  //     if(success.status == 200){
  //       props.logout_req();
  //     }
  //     }
      
  //   })

      const result = await API.get(API_URL.VERIFY_LOGOUT, API.getHeaders());
      let response = await result.data;
      console.log("response values of logout api ", response);
      if (response.status === 200) {
        //  toaster('success', response.message);
        props.logout_req();

      }
    }

  }

  const getUersById = async () => {
    if (props.user?.userId != null) {
      const data = {
        "userid": props.user?.userId
      }
      await props.user_by_id_req(data, (error, success) => {
        if (error) {

          console.log("error", error);
        }
        if (success.status == 200) {

          setFirstName((success && success.response && (success.response.length != 0) && success.response[0].first_name));
          setLastName((success && success.response && (success.response.length != 0) && success.response[0].last_name));
          setPhoto((success && success.response && (success.response.length != 0) && success.response[0].photo));
          setEmail((success && success.response && (success.response.length != 0) && success.response[0].email));

        }
      })
    }
  }
  useEffect(() => {
    //verify_logout();
    getUersById();
  }, [props.user?.userId])

  const handleLogout = () => {
    verify_logout();
  }


  const handleDropdownClick = () => {
    if (props.cart.length > 0) {
      // Navigate to the cart page
      window.location.href = "/users/addToCart";
    } else {
      // Show a toaster notification
      toaster("success", "Please add a test or package");
    }
  };

  const getAllCustomNotificationApiCall = async() =>{
    if(props.user?.userId != null){
    await props.all_custom_notification_req(null,(error,success)=>{
      if(error){
        
        console.log("error",error);
      }    
      if(success.status == 200 ){
        if (success && success?.response.length > 0) {
          var filterData = [];

            success.response.map((cstm_notification, idx) => {
              if ((cstm_notification.read_status == 0)) {
                filterData.push(cstm_notification);
              }
            })

            setCustomNotificationData(filterData);

          }
          if (success && success?.response.length <= 0) {
            console.log("data custom notification length empty ", success?.message);

          }
        }
      });
    }

  }

  // useEffect(() => {

  //   getAllCustomNotificationApiCall();
  // },[])

  const postCustomNotificationReadStatusApiCall = async (dataValue) => {
    console.log("data from onClick post cunstom notifications api call value ===", dataValue);
    const data = {
      "customNotificationId": dataValue.id
    }
    await props.custom_notification_read_status_by_id_request(data, (error, success) => {
      if (error) {

        console.log("error", error);
      }
      if (success.status == 200) {

        nextPathDataPush(`/Notifications/NotificationsView`, dataValue);

      }
    })

  }





  const onClickIndNotification = (data) => {
    console.log("data is sent onClickNotification", data)
    postCustomNotificationReadStatusApiCall(data);
  }

  { console.log("photo", `${API_URL_ROOT}/${photo}`) }
  // console.log("header home url ==== ",urlHome);
  //toggle menu
  // if(showMediaIcons){
  //   menu=  <nav>
  //        {/*} <div className="main-menu-wrapper">*/}
  //       {/*} <div className="main-menu-wrapper">*/}
  //        {/*<div className="menu-header">*/}
  //        <div className="slide-bar">

  //         <ul className="main-nav"> 
  //            <li className={pathnames.includes("/home") ? "active" : ""}><Link to="/home">Home</Link></li>
  //             <li className={pathnames.includes("infrastructure") ? "active" : ""}><Link to="/home/infrastructure">Infrastructure</Link></li>
  //             <li className={pathnames.includes("allTests") ? "active" : ""}><Link to="/home/allTestsView/allTests">Test</Link></li>
  //             <li className={pathnames.includes("allPackages") ? "active" : ""}><Link to="/home/allPackagesView/allPackages">Packages</Link></li>
  //             <li className={pathnames.includes("/faq") ? "active" : ""}><Link to="/home/faq">FAQs</Link></li>
  //             <li className={pathnames.includes("articles/articleView") ? "active" : ""}><Link to="/home/articles/articleView">Articles</Link></li>
  //             <li className="login-link" onClick={()=>onhandleCloseMenu()}>
  //               <Link to="/">Login / Signup</Link>
  //             </li>

  //           </ul>

  //       {/*} </div>*/}

  //       {/* </div>*/}
  //        </div>
  //       </nav>
  // }
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onhandleCloseMenu(); // Close the menu
                                              
    }
  };

  useEffect(() => {
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
     
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 

  return (
    <>
      <div className="top-header">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-6">
              <div className="left">
                <ul>
                  {/* <li><span><i className="fas fa-phone-alt"></i> Contact Number : 080-23100735/38</span></li> */}
                  <li><span><i className="fas fa-map-marker-alt"></i> Location : #97, 17th Cross, MC Layout,Chord Road, Vijayanagar,
                    Bengaluru</span></li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 top-right">
              <div className="right">
                <ul>
                  <li><span><i className="fas fa-calendar-check"></i>Monday – Saturday 06:30 AM – 08:30 PM | Sunday 06:30 AM – 12:30 PM</span></li>
                </ul>
              </div>

              {/* <div className="download">
            <img src={playstore} className="img-fluid downloadimg" alt="Logo" />
            <img src={appstore} className="img-fluid downloadimg" alt="Logo" />
            </div> */}

            </div>
          </div>
        </div>
      </div>
      {(props.user != null) ? (props.user?.userId != null) ? (
        <div className="header user-header home sticky ">
          {/* Logo */}
          <div className="row">

            <div className="header-center text-center logo-container">
              <a href="#0" className="logo">
                <img src={logo} className="img-fluid company-logo" alt="Logo" />
              </a>
              {/* <a href="/#0" className="logo logo-small">
              <img src={logo} alt="Logo" width="30" height="30" />
            </a> */}
            </div>
            {/* /Logo */}
            <a href="#0" id="toggle_btn"
            // onClick={this.handlesidebar}
            >
              <i className="fa fa-text-align-left" />
            </a>
            <div className="top-nav-search mr-5 px-1 py-3 d-none d-sm-block">
              <div className="parent-container">
                <div className="">
                  <form className="search-form search-box ">
                    <select className="form-control" aria-label="Select location">
                      <option className="location" value="">Select Location</option>
                      <option value="location1">Location 1</option>
                      <option value="location2">Location 2</option>
                      <option value="location3">Location 3</option>
                    </select>
                    <div className="input-group input-box">
                      <input type="text" placeholder="Search here..." className="form-control" />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="contactnumber">
            <span><i className="fas fa-phone-alt"></i>080-23100735/38</span>
            </div> */}
            <div className="download">
              <div className="img-download">
                <img src={playstore} className="img-fluid downloadimg" alt="Logo" />
                <img src={appstore} className="img-fluid downloadimg" alt="Logo" />
              </div>
            </div>

            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/home" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="LogoMobile" />
                </Link>
                <a
                  href="#0"
                  id="menu_close"
                  className="menu-close"
                  onClick={() => onhandleCloseMenu()}
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
              {/* mobile nav */}
              <ul className="main-nav small-nav"  ref={menuRef}>
                <li className={urlHome.includes("home") ? "" : ""} onClick={() => onhandleCloseMenu()} ><Link to="/home" >Home</Link></li>
                <li className={url.includes("AboutContent") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/AboutContent">About us</Link></li>

                <li className={url.includes("infrastructure") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/infrastructure">Infrastructure</Link></li>
               
                <li className={urlCustom.includes("allTests") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allTestsView/allTests" >Test</Link></li>
                <li className={urlCustom.includes("allPackages") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allPackagesView/allPackages" >Packages</Link></li>
                <li className={url.includes("faq") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/faq" >FAQs</Link></li>
                <li className={url.includes("articles") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/articles/articleView">Articlessss</Link></li>

              </ul>



            </div>

            {/* Mobile Menu Toggle */}
            {/* <a href="#0" className="mobile_btn" id="mobile_btn">
            <i className="fa fa-bars" />
          </a> */}
            <a href="#0" id="mobile_btn" onClick={() => onHandleMobileMenu()}>
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>

            </a>
            {/* <div className="download">
            <img src={playstore} className="img-fluid downloadimg" alt="Logo" />
            <img src={appstore} className="img-fluid downloadimg" alt="Logo" />
            </div>
             */}
            {/* /Notificatons */}
           
            <div className="customnav">
              <ul className="main-nav lapnav">
              
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home" exact activeClassName="active-link">Home</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/AboutContent" activeClassName="active-link">About us</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/infrastructure" activeClassName="active-link">Infrastructure</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/allTestsView/allTests" activeClassName="active-link">Test</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/allPackagesView/allPackages" activeClassName="active-link">Packages</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/faq" activeClassName="active-link">FAQs</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/articles/articleView" activeClassName="active-link">Articles</NavLink>
                </li>
                <li className="nav-item dropdown noti-dropdown mr-1 nav-right">
                  {/* <Dropdown className="notify">
                <Dropdown.Toggle
                  className="dropdown-toggle nav-link"
                  id="dropdown-basic"
                >
                  <i className="fa fa-bell"></i>{" "}
                 {(customNotificationData.length > 0)?(<span className="badge badge-pill">{(customNotificationData.length > 0)? customNotificationData.length : 0}</span>):null} 
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-list">
                 
                
                <Dropdown.Item
                    href="/"
                    className="notification-message"
                  >
                    <span>Notification</span>
                  </Dropdown.Item>

                  {(customNotificationData.length > 0)?(
                  customNotificationData.map((cstm_notification, idx) =>(
                    <Dropdown.Item
                    className="notification-message"
                    key={idx}
                    onClick={() => onClickIndNotification(cstm_notification)}
                  >
                    <div className="media">
                    
                      <div className="media-body">
                        <p className="noti-details">
                          <span className="noti-title">{((cstm_notification.title != "undefined")||(cstm_notification.title != undefined) || (cstm_notification.title != null)) ? cstm_notification.title : '--'}</span>
                        </p>
                        <p className="noti-details">
                          <span className="noti-title">{((cstm_notification.message != "undefined")||(cstm_notification.message != undefined) || (cstm_notification.message != null)) ? cstm_notification.message : '--'}</span>
                        </p>

                        <p className="noti-time">
                          <span className="notification-time">{((cstm_notification.created_at != "undefined")||(cstm_notification.created_at != undefined) || (cstm_notification.created_at != null)) ? moment(cstm_notification.created_at).fromNow() : null}</span>
                        </p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  
                  ))
                 ):null}
                 
                 
                  <Dropdown.Item
                    href="/Notifications/NotificationsViewAll"
                    className="notification-message text-center"
                  >
                    <span className="text-center">View all</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
                  <CustomNotificationHeader {...props} />
                </li>

                {/* Cart  */}

                <li className="nav-item dropdown noti-dropdown mr-1 nav-right">
                  <Dropdown className="notify">
                    <Dropdown.Toggle
                      className="dropdown-toggle nav-link"
                      id="dropdown-basic"
                      onClick={handleDropdownClick}
                      // onClick={() => window.location.href = '/users/addToCart'}
                    >
                      <i className="fas fa-shopping-cart" style={{ color: 'white' }}></i>{" "}
                      {((props.cart.length > 0) ? (<span className="badge badge-pill">{(props.cart.length > 0) ? props.cart.length : 0}</span>) : null)}
                    </Dropdown.Toggle>

                    {/* <Dropdown.Menu className="notification-list"> */}


                      {/* <Dropdown.Item
                        href="/users/addToCart"
                        className="notification-message"
                      >
                        <span>View All</span>
                      </Dropdown.Item> */}

                      {/* <Dropdown.Item
                    href="#/action-1"
                    className="notification-message text-center"
                  >
                    <span className="text-center">View all</span>
                  </Dropdown.Item> */}
                    {/* </Dropdown.Menu> */}
                  </Dropdown>
                </li>
                {/* User Profile  */}
                <li className="nav-item dropdown noti-dropdown ">
                  <Dropdown className="notify">
                    <Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
                      <span className="user-img">
                        <img
                          className="rounded-circle "
                          // src={(photo == null)?avatar:`${API_URL_ROOT}/${photo}`}
                          src={(photo == null) ? userIcon : `${API_URL_ROOT}/${photo}`}
                          // src={avatar}
                          width="31"

                        />
                        {/* alt={((firstName != null) && (lastName != null))?`${firstName} ${lastName}` : "User name"} */}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/users/dashboard" className="no-padding">
                        <div className="user-header">
                          <div className="avatar avatar-sm">
                            <img
                              src={(photo == null) ? userIcon : `${API_URL_ROOT}/${photo}`}
                              // {console.log("photo",`${API_URL_ROOT}/${photo}`)}
                              // src={avatar}
                              alt="User"
                              className="avatar-img rounded-circle"
                            />
                          </div>
                          <div className="user-text">
                            <h6>{((firstName != null) && (lastName != null)) ? `${firstName} ${lastName}` : "User name"}</h6>
                            <p className="text-muted emailtext mb-0">{(email != null) ? `${email}` : "Email"}</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#0"> My Profile</Dropdown.Item>
                  <Dropdown.Item href="#0">Settings</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleLogout()} >Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

              </ul>
            </div>
            <ul className="nav user-menu px-1 py-3 navnbar_1 smallcart">
              <li className="nav-item dropdown noti-dropdown mr-1">
                {/* <Dropdown className="notify">
                <Dropdown.Toggle
                  className="dropdown-toggle nav-link"
                  id="dropdown-basic"
                >
                  <i className="fa fa-bell"></i>{" "}
                 {(customNotificationData.length > 0)?(<span className="badge badge-pill">{(customNotificationData.length > 0)? customNotificationData.length : 0}</span>):null} 
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-list">
                 
                
                <Dropdown.Item
                    href="/"
                    className="notification-message"
                  >
                    <span>Notification</span>
                  </Dropdown.Item>

                  {(customNotificationData.length > 0)?(
                  customNotificationData.map((cstm_notification, idx) =>(
                    <Dropdown.Item
                    className="notification-message"
                    key={idx}
                    onClick={() => onClickIndNotification(cstm_notification)}
                  >
                    <div className="media">
                    
                      <div className="media-body">
                        <p className="noti-details">
                          <span className="noti-title">{((cstm_notification.title != "undefined")||(cstm_notification.title != undefined) || (cstm_notification.title != null)) ? cstm_notification.title : '--'}</span>
                        </p>
                        <p className="noti-details">
                          <span className="noti-title">{((cstm_notification.message != "undefined")||(cstm_notification.message != undefined) || (cstm_notification.message != null)) ? cstm_notification.message : '--'}</span>
                        </p>

                        <p className="noti-time">
                          <span className="notification-time">{((cstm_notification.created_at != "undefined")||(cstm_notification.created_at != undefined) || (cstm_notification.created_at != null)) ? moment(cstm_notification.created_at).fromNow() : null}</span>
                        </p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  
                  ))
                 ):null}
                 
                 
                  <Dropdown.Item
                    href="/Notifications/NotificationsViewAll"
                    className="notification-message text-center"
                  >
                    <span className="text-center">View all</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
                <CustomNotificationHeader {...props} />
              </li>

              {/* Cart  */}

            <li className="nav-item dropdown noti-dropdown mr-1">
              {/* <Dropdown className="notify">
                <Dropdown.Toggle
                  className="dropdown-toggle nav-link"
                  id="dropdown-basic"
                >
                  <i className="fas fa-shopping-cart"></i>{" "}
                  {((props.cart.length > 0)?(<span className="badge badge-pill">{(props.cart.length > 0)? props.cart.length : 0}</span>):null)}
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-list">
                 
                 
                <Dropdown.Item
                    href="/users/addToCart"
                    className="notification-message"
                  >
                    <span>View All</span>
                  </Dropdown.Item> 
                  
                   <Dropdown.Item
                    href="#/action-1"
                    className="notification-message text-center"
                  >
                    <span className="text-center">View all</span>
                  </Dropdown.Item> 
                </Dropdown.Menu>
              </Dropdown> */}

              {/* <Dropdown className="notify">
                
      <Dropdown.Toggle
        className="dropdown-toggle nav-link"
        id="dropdown-basic"
        href="/users/addToCart"
        style={{
          background: "none",
          border: "none",
          color: "#0066b5",
 
          alignItems: "center",
          justifyContent: "center",
        }}
      >
       <i
  className="fas fa-shopping-cart"
  style={{
    fontSize: "25px",

  }}
></i>
        {props.cart.length > 0 && (
          <span className="badge badge-pill">{props.cart.length}</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="notification-list">
        {/* Add any other dropdown items here */}
        {/* <div className="no-items">No additional options</div>
      </Dropdown.Menu>
    </Dropdown> */}



<Dropdown className="notify">
      <Dropdown.Toggle
        className="dropdown-toggle nav-link"
        id="dropdown-basic"
        style={{
          background: "none",
          border: "none",
          color: "#0066b5",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleDropdownClick} // Call the handler on click
      >
        <i
          className="fas fa-shopping-cart"
          style={{
            fontSize: "25px", // Adjust the icon size
          }}
        ></i>
        {props.cart.length > 0 && (
          <span className="badge badge-pill">{props.cart.length}</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="notification-list">
        {/* Add any other dropdown items here */}
        <div className="no-items">No additional options</div>
      </Dropdown.Menu>
    </Dropdown>
            </li>
                {/* User Profile  */}
            <li className="nav-item dropdown noti-dropdown ">
              <Dropdown className="notify">
                <Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
                  <span className="user-img">
                    <img
                      className="rounded-circle"
                      // src={(photo == null)?avatar:`${API_URL_ROOT}/${photo}`}
                      src={(photo == null)?userIcon:`${API_URL_ROOT}/${photo}`}
                      // src={avatar}
                      width="31"
                     
                    />
                    {/* alt={((firstName != null) && (lastName != null))?`${firstName} ${lastName}` : "User name"} */}
                  </span>
                </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/users/dashboard" className="no-padding">
                      <div className="user-header mobile-profile mobile-avatar">
                        <div className="avatar avatar-sm">
                          <img
                            src={(photo == null) ? userIcon : `${API_URL_ROOT}/${photo}`}
                            // {console.log("photo",`${API_URL_ROOT}/${photo}`)}
                            // src={avatar}
                            alt="User"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="user-text">
                          <h6>{((firstName != null) && (lastName != null)) ? `${firstName} ${lastName}` : "User name"}</h6>
                          <p className="text-muted emailtext mb-0">{(email != null) ? `${email}` : "Email"}</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="#0"> My Profile</Dropdown.Item>
                  <Dropdown.Item href="#0">Settings</Dropdown.Item> */}
                    <Dropdown.Item onClick={() => handleLogout()} >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <div>
          <Sidebar />
          </div>
          </div>
        </div>
        

      ) : (
        <div className="header user-header home sticky ">
          {/* Logo */}
          <div className="row">

            <div className="header-center text-center logo-container">
              <a href="#0" className="logo">
                <img src={logo} className="img-fluid company-logo" alt="Logo" />
              </a>
              {/* <a href="/#0" className="logo logo-small">
              <img src={logo} alt="Logo" width="30" height="30" />
            </a> */}
            </div>
            {/* /Logo */}
            <a href="#0" id="toggle_btn"
            // onClick={this.handlesidebar}
            >
              <i className="fa fa-text-align-left" />
            </a>
            <div className="top-nav-search mr-5 px-1 py-3 d-none d-sm-block">
              <div className="parent-container">
                <div className="">
                  <form className="search-form search-box ">
                    <select className="form-control" aria-label="Select location">
                      <option className="location" value="">Select Location</option>
                      <option value="location1">Location 1</option>
                      <option value="location2">Location 2</option>
                      <option value="location3">Location 3</option>
                    </select>
                    <div className="input-group input-box">
                      <input type="text" placeholder="Search here..." className="form-control" />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="contactnumber">
            <span><i className="fas fa-phone-alt"></i>080-23100735/38</span>
            </div> */}
            <div className="download">
              <div className="img-download">
                <img src={playstore} className="img-fluid downloadimg" alt="Logo" />
                <img src={appstore} className="img-fluid downloadimg" alt="Logo" />
              </div>
            </div>

            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/home" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="LogoMobile" />
                </Link>
                <a
                  href="#0"
                  id="menu_close"
                  className="menu-close"
                  onClick={() => onhandleCloseMenu()}
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <ul className="main-nav small-nav">
                <li className={urlHome.includes("home") ? "" : ""} onClick={() => onhandleCloseMenu()} ><Link to="/home" >Home</Link></li>
                <li className={url.includes("AboutContent") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/AboutContent">About us</Link></li>

                <li className={url.includes("infrastructure") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/infrastructure">Infrastructure</Link></li>
                <li className={urlCustom.includes("allTests") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allTestsView/allTests" >Test</Link></li>
                <li className={urlCustom.includes("allPackages") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allPackagesView/allPackages" >Packages</Link></li>
                <li className={url.includes("faq") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/faq" >FAQs</Link></li>
                <li className={url.includes("articles") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/articles/articleView">Articles</Link></li>
                <li className="login-link mobile-login .d-none .d-sm-block" onClick={() => onhandleCloseMenu()}>
                  <Link to="/home/login" >Login / Signup</Link>
                </li>


              </ul>
              {/* <ul className="nav header-navbar-rht ">
              <li className="nav-item">
                <Link to="/home/login" className="nav-link header-login">
                  login / Signup{" "}
                </Link>
              </li>{" "}


            </ul> */}

            </div>

            {/* Mobile Menu Toggle */}
            {/* <a href="#0" className="mobile_btn" id="mobile_btn">
            <i className="fa fa-bars" />
          </a> */}
            <a href="#0" id="mobile_btn" onClick={() => onHandleMobileMenu()}>
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>

            </a>
            {/* <div className="download">
            <img src={playstore} className="img-fluid downloadimg" alt="Logo" />
            <img src={appstore} className="img-fluid downloadimg" alt="Logo" />
            </div>
             */}
            {/* /Notificatons */}
            <div className="customnav">
              <ul className="main-nav lapnav">
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/" exact activeClassName="active-link">Home</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/AboutContent" activeClassName="active-link">About us</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/infrastructure" activeClassName="active-link">Infrastructure</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/allTestsView/allTests" activeClassName="active-link">Test</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/allPackagesView/allPackages" activeClassName="active-link">Packages</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/faq" activeClassName="active-link">FAQs</NavLink>
                </li>
                <li onClick={onhandleCloseMenu}>
                  <NavLink to="/home/articles/articleView" activeClassName="active-link">Articles</NavLink>
                </li>
                <li className="nav-item dropdown noti-dropdown mr-1 px-1 py-3">
                  <Dropdown className="notify notify-cart">
                    <Dropdown.Toggle
                      className="dropdown-toggle nav-link"
                      id="dropdown-basic"
                    >
                      <i className="fas fa-shopping-cart"></i>{" "}
                      {((props.cart.length > 0) ? (<span className="badge badge-pill">{(props.cart.length > 0) ? props.cart.length : 0}</span>) : null)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="notification-list">


                      <Dropdown.Item
                        href={((props.user?.userId == null) || (props.user?.user_by_id == null)) ? "/home/login" : "/users/addToCart"}
                        className="notification-message"
                      >
                        <span>View All</span>
                      </Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>
                </li>


                {/* <li className="login-link" onClick={() => onhandleCloseMenu()}>
                  <Link to="//home/login" >Login / Signup</Link>
                </li> */}

              </ul>
              <ul className="nav header-navbar-rht " style={{ marginLeft: "-70px" }}>
                <li className="nav-item">
                  <Link to="/home/login" className="nav-link header-login ">
                    login / Signup{" "}
                  </Link>
                </li>{" "}


              </ul>
            </div>

          </div>
          <div>
          <Sidebar />
          </div>
        </div>
       
        

      ) : (
        <header className="header home sticky">

          <nav className="navbar navbar-expand-lg header-nav sticky-top">
            <div className="navbar-header ">
              <a href="#0" id="mobile_btn" onClick={() => onHandleMobileMenu()}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>

              </a>
              <Link to="/home" className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/home" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="LogoMobile" />
                </Link>
                <a
                  href="#0"
                  id="menu_close"
                  className="menu-close"
                  onClick={() => onhandleCloseMenu()}
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <ul className="main-nav">
                <li className={urlHome.includes("home") ? "" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home" >Home</Link></li>
                <li className={url.includes("AboutContent") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/AboutContent">About</Link></li>
                <li className={url.includes("infrastructure") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/infrastructure">Infrastructure</Link></li>
                <li className={urlCustom.includes("allTests") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allTestsView/allTests" >Test</Link></li>
                <li className={urlCustom.includes("allPackages") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allPackagesView/allPackages" >Packages</Link></li>
                <li className={url.includes("faq") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/faq" >FAQs</Link></li>
                <li className={url.includes("articles") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/articles/articleView">Articles</Link></li>


                <li className="login-link" onClick={() => onhandleCloseMenu()}>
                  <Link to="home/login" >Login / Signup</Link>
                </li>
              </ul>


            </div>



          </nav>
        </header>


      )}

      {/* <ul className="main-nav ">
                <li className={urlHome.includes("home") ? "" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home" >Home</Link></li>
                <li className={url.includes("AboutContent") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/AboutContent">About us</Link></li>

                <li className={url.includes("infrastructure") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/infrastructure">Infrastructure</Link></li>
                <li className={urlCustom.includes("allTests") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allTestsView/allTests" >Test</Link></li>
                <li className={urlCustom.includes("allPackages") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/allPackagesView/allPackages" >Packages</Link></li>
                <li className={url.includes("faq") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/faq" >FAQs</Link></li>
                <li className={url.includes("articles") ? "active" : ""} onClick={() => onhandleCloseMenu()}><Link to="/home/articles/articleView">Articles</Link></li>

              </ul> */}
    </>

  );
};
// const memoPrevDataCompare = (prevProps, nextProps) =>{
//   const prevPropData = JSON.stringify(prevProps.notificationData);
//   const nextPropData = JSON.stringify(nextProps.notificationData);

//  const dataFetchedComparator = prevProps.notificationData.map((cstm_notification, idx) =>{

//   nextProps.notificationData.map((cstmnext_notification, idx) =>{
//     if((cstm_notification.read_status !== cstmnext_notification.read_status)){
//       return true;   
//     }
//     return false;
//   })




//         })

// return dataFetchedComparator

// }

const mapDispatchToProps = (dispatch) => {
  return {
    logout_req: () => dispatch(logout_req()),

    user_logout_req: (data, callback) => dispatch(logout_request(data, callback)),

    user_by_id_req: (data, callback) => dispatch(get_user_by_id_req(data, callback)),
    all_custom_notification_req: (data, callback) => dispatch(get_all_custom_notification_req(data, callback)),
    custom_notification_read_status_by_id_request: (data, callback) => dispatch(post_custom_notification_read_status_by_id_request(data, callback)),
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,
    user_by_id: state.user.user_by_id,
    cart: state.cart.cartVal,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting,
    notificationData: state.customnotification.all_customnotification,


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

