import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { connect } from "react-redux";
import { useHistory } from "react-router";
import _ from 'underscore';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG_sp_02, IMG_sp_03, IMG_sp_04, IMG_sp_05, IMG_fe_01, IMG_fe_02, IMG_fe_03, IMG_fe_04 } from './img';
import { get_all_test_req, get_all_test_call_back_req } from "../../../../../redux-services/actions/action.test/action.function.js"
import img1 from "../../../../../assets/images/features/feature-01.jpg";
import img2 from "../../../../../assets/images/features/feature-02.jpg";
import img3 from "../../../../../assets/images/features/feature-03.jpg";
import img4 from "../../../../../assets/images/features/feature-04.jpg";
import testImage from "../../../../../assets/images/tests.png";
import { add_to_cart_request, delete_from_cart_request } from "../../../../../redux-services/actions/action.cart/action.function.js"
import "./test.css"

const images = [img1, img2, img3, img4];
function SearchList(props) {
    const [photoIndex, setPhotIndex] = useState(0);
    const [cartVar, setCartVar] = useState([]);
    console.log("the value is ", cartVar)
    const [categoryTestCartFilterVar, setCategoryTestCartFilterVar] = useState([]);
    const [popularTestCartFilterVar, setPopularTestCartFilterVar] = useState([]);

    const [allTestData, setAllTestData] = useState([]);
    const [populartestData, setPopularTestData] = useState([]);
    const [categoryFilteredData, setCategoryFilteredData] = useState([]);


    //  const getAllTestApi = () =>{
    //     const { get_all_test } =props;
    //     get_all_test();
    //      const all_test = props.all_test;
    // //   console.log("gen_table =====",all_test);

    //  } 


    //  const getAllTestApi = async() =>{

    //     await props.get_all_test_call_back(null,(error,success)=>{
    //      if(error){

    //        console.log("error",error);
    //      }    
    //      if(success.status == 200 ){
    //        if (success && success?.response.length > 0) {
    //         setTestData(success?.response);
    //         let selectCartVar = new Array(success?.response.length).fill(false);
    //             setCartVar(selectCartVar);

    //             if(props.cart.length > 0){
    //                 props.success.response.map((test,idx) =>{
    //                    cartIncludeIn = props.cart.some(function(item) {
    //                     console.log("cart ids ===", item.serviceid);
    //                     console.log("test ids ===", test.serviceid);
    //                         return item.serviceid === test.serviceid

    //                     });
    //                     console.log("test array in cart boolean ===", cartIncludeIn);


    //                     if(cartIncludeIn == true){
    //                         let newCartVar = [...cartVar];

    //                         return newCartVar[idx] = true;
    //                     }
    //                     if(cartIncludeIn == false){
    //                         let newCartVar = [...cartVar];
    //                         return newCartVar[idx] = false ;
    //                     }

    //             });
    //         }




    //        }
    //        if (success && success?.response.length <= 0) {
    //          console.log("data orders length empty",success?.message);
    //         //  setNoOrdersData(success?.message);
    //        }
    //       }
    //       });


    //    }
    var cartIncludeInArray = [];
    //  var cartbtn;


    const cartValueGreaterCheck = () => {

        let selectCartVar = new Array(props.all_test?.response.length).fill(false);
        // setCartVar(selectCartVar);
        props.cart.map((data, idx) => {
            let indexCartValue = props.all_test?.response.findIndex((test) => {
                return test.serviceid === data.serviceid
            })

            console.log("index includes values ", indexCartValue);
            if (indexCartValue == -1) {
                setCartVar(selectCartVar);
            }
            else {

                let cartIncludeIn = props.all_test.response.some(function (test) {
                    console.log("cart ids ===", data.serviceid);
                    console.log("test ids ===", test.serviceid);
                    return test.serviceid === data.serviceid

                });

                if (cartIncludeIn == true) {
                    // let newCartVar = [...cartVar];                           
                    //  newCartVar[indexCartValue] = true;
                    //  console.log("index includes cartVar",newCartVar);
                    // setCartVar(newCartVar);

                    // let newCartVar = cartVar.map((val, idx) => idx === indexCartValue ? !val : val);

                    //  setCartVar(newCartVar);

                    //  let newCartVar = selectCartVar.map((val, idx) => idx === indexCartValue ? !val : val);
                    selectCartVar[indexCartValue] = true
                    setCartVar(selectCartVar);

                }

            }



        });

        // popular Test cart val

        let selectPopularTestCartVar = new Array(populartestData.length).fill(false);
        props.cart.map((data, idx) => {
            let indexPopularTestCartValue = populartestData.findIndex((test) => {
                return test.serviceid === data.serviceid
            })

            console.log("index populartestData includes values ", indexPopularTestCartValue);
            if (indexPopularTestCartValue == -1) {
                setPopularTestCartFilterVar(selectPopularTestCartVar);
            }
            else {

                let cartPopularTestIncludeIn = populartestData.some(function (test) {
                    console.log("cart ids ===", data.serviceid);
                    console.log("test ids ===", test.serviceid);
                    return test.serviceid === data.serviceid

                });

                if (cartPopularTestIncludeIn == true) {
                    selectPopularTestCartVar[indexPopularTestCartValue] = true
                    setPopularTestCartFilterVar(selectPopularTestCartVar);

                }

            }



        });


    }

    const getAllTestApi = () => {
        // const { get_all_test } =props;
        // get_all_test();
        //  const all_test = props.all_test;
        //   console.log("gen_table =====",all_test);
        props.get_all_test(null, (error, success) => {
            if (error) {

                console.log("error", error);
            }
            if (success.status == 200) {
                if ((success && success?.response.length > 0)) {
                    let poularTestDataValues = success?.response.filter((data, idx) => { return (data.popular_service == 1) }
                    )
                    setPopularTestData(poularTestDataValues);
                    // let selectPopularTestCartVar = new Array(poularTestDataValues.length).fill(false);
                    // setPopularTestCartFilterVar(selectPopularTestCartVar);
                    setAllTestData(success?.response);

                    if (props.cart.length > 0) {
                        cartValueGreaterCheck();
                    } else {
                        let selectCartVar = new Array(success?.response.length).fill(false);

                        setCartVar(selectCartVar);

                        // popular test data
                        let selectPopularTestCartVar = new Array(poularTestDataValues).fill(false);
                        setPopularTestCartFilterVar(selectPopularTestCartVar);

                    }

                }

                if ((success && success?.response.length < 0)) {
                    setAllTestData([]);
                }
            }
        })

    }

    console.log("popular test data ==", populartestData);
    console.log("test filter based on category==", props.searchFilterCategoryData);
    console.log("AllTestData ====", allTestData);

    useEffect(() => {
        getAllTestApi();

    }, []);

   



    const handleCart = async(data,id,dataGrp) =>{
        
        
           console.log("data id passed ===", id);  
           if(dataGrp == "popularTest"){
            if((popularTestCartFilterVar[id] == false)){
                await props.add_to_cart(data,(error,success)=>{
                    if(error){
                      
                      console.log("error",error);
                    } 
                    if(success){
                        console.log("success",success);
                       let newPopularTestCartFilterVar = [...popularTestCartFilterVar];
                       newPopularTestCartFilterVar[id] = true;
                       setPopularTestCartFilterVar(newPopularTestCartFilterVar);
                    }
                })
            }
            else {
                await props.delete_from_cart(data, (error, success) => {
                    if (error) {

                        console.log("error", error);
                    }
                    if (success) {
                        console.log("success", success);
                        let newPopularTestCartFilterVar = [...popularTestCartFilterVar];
                        newPopularTestCartFilterVar[id] = false;
                        setPopularTestCartFilterVar(newPopularTestCartFilterVar);
                    }
                })
            }

        }
        if (dataGrp == "categoryFilterTest") {
            if ((categoryTestCartFilterVar[id] == false)) {
                await props.add_to_cart(data, (error, success) => {
                    if (error) {

                        console.log("error", error);
                    }
                    if (success) {
                        console.log("success", success);
                        let newCategoryTestCartFilterVar = [...categoryTestCartFilterVar];
                        newCategoryTestCartFilterVar[id] = true;
                        setCategoryTestCartFilterVar(newCategoryTestCartFilterVar);
                    }
                })
            }
            else {
                await props.delete_from_cart(data, (error, success) => {
                    if (error) {

                        console.log("error", error);
                    }
                    if (success) {
                        console.log("success", success);
                        let newCategoryTestCartFilterVar = [...categoryTestCartFilterVar];
                        newCategoryTestCartFilterVar[id] = false;
                        setCategoryTestCartFilterVar(newCategoryTestCartFilterVar);
                    }
                })
            }
            
        } 
        if(dataGrp == "allTest"){
            if((cartVar[id] == false)){
                await props.add_to_cart(data,(error,success)=>{
                    console.log("success@@@@@@dddd",data);
                    if(error){
                      
                      console.log("error",error);
                    } 
                    if(success){
                        console.log("success@@@@@@",success);
                       let newCartVar = [...cartVar];
                       newCartVar[id] = true;
                       setCartVar(newCartVar);
                       console.log("newCartVar!!!!!!!!",newCartVar);
                    }
                })
            }
            else {
                await props.delete_from_cart(data, (error, success) => {
                    if (error) {

                        console.log("error", error);
                    }
                    if (success) {
                        console.log("success", success);
                        let newCartVar = [...cartVar];
                        newCartVar[id] = false;
                        setCartVar(newCartVar);
                    }
                })
            }

        }
        // dataPresentInCart(data,id);


    }
    console.log("cart val setting=========", cartVar);

    console.log("popularTestCartFilterVar ===========", popularTestCartFilterVar);

    // use effect for search Category filter
    useEffect(() => {

        if (((props.all_test?.response.length != 0) && (props.searchFilterCategoryData.length != 0))) {
            let filteredList = props.all_test?.response.filter(data =>
                props.searchFilterCategoryData.includes(data.cat_name)
            );
            setCategoryFilteredData(filteredList);

            // filter category Test cart val setCategoryTestCartFilterVar

            let selectCategoryTestCartVar = new Array(filteredList.length).fill(false);
            props.cart.map((data, idx) => {
                let indexCategoryTestCartValue = filteredList.findIndex((test) => {
                    return test.serviceid === data.serviceid
                })

                console.log("index CategoryTestData includes values ", indexCategoryTestCartValue);
                if (indexCategoryTestCartValue == -1) {
                    setCategoryTestCartFilterVar(selectCategoryTestCartVar);
                }
                else {

                    let cartCategoryTestIncludeIn = filteredList.some(function (test) {
                        console.log("cart ids ===", data.serviceid);
                        console.log("test ids ===", test.serviceid);
                        return test.serviceid === data.serviceid

                    });

                    if (cartCategoryTestIncludeIn == true) {
                        // let newCartVar = [...cartVar];                           
                        //  newCartVar[indexPopularTestCartValue] = true;
                        //  console.log("index includes cartVar",newCartVar);
                        // setCartVar(newCartVar);

                        // let newCartVar = cartVar.map((val, idx) => idx === indexPopularTestCartValue ? !val : val);

                        //  setCartVar(newCartVar);

                        //  let newCartVar = selectCartVar.map((val, idx) => idx === indexPopularTestCartValue ? !val : val);
                        selectCategoryTestCartVar[indexCategoryTestCartValue] = true
                        setCategoryTestCartFilterVar(selectCategoryTestCartVar);

                    }

                }



            });



        }
        else {
            setCategoryFilteredData([]);
            setCategoryTestCartFilterVar([]);
        }
    }, [props.searchFilterCategoryData]);

    console.log("categoryTestCartFilterVar ===========", categoryTestCartFilterVar);

    useEffect(() => {

        // popular Test cart val
        if ((props.popularTestFilter == true) && (populartestData.length != 0)) {
            let selectPopularTestCartVar = new Array(populartestData.length).fill(false);
            props.cart.map((data, idx) => {
                let indexPopularTestCartValue = populartestData.findIndex((test) => {
                    return test.serviceid === data.serviceid
                })

                console.log("index populartestData includes values ", indexPopularTestCartValue);
                if (indexPopularTestCartValue == -1) {
                    setPopularTestCartFilterVar(selectPopularTestCartVar);
                }
                else {

                    let cartPopularTestIncludeIn = populartestData.some(function (test) {
                        console.log("cart ids ===", data.serviceid);
                        console.log("test ids ===", test.serviceid);
                        return test.serviceid === data.serviceid

                    });

                    if (cartPopularTestIncludeIn == true) {
                        selectPopularTestCartVar[indexPopularTestCartValue] = true
                        setPopularTestCartFilterVar(selectPopularTestCartVar);

                    }

                }



            });
        }

    }, [props.popularTestFilter == true])
    useEffect(() => {
        if ((props.all_test && (props.all_test?.response.length > 0))) {

            console.log("cart.length", props.cart.length);
            if (props.cart.length > 0) {
                cartValueGreaterCheck();
            }
            if (props.cart.length == 0) {
                let selectCartVar = new Array(props.all_test?.response.length).fill(false);
                setCartVar(selectCartVar);

                // popular test data
                let selectPopularTestCartVar = new Array(populartestData.length).fill(false);
                setPopularTestCartFilterVar(selectPopularTestCartVar);

                // category test data
                let selectCategoryTestCartVar = new Array(categoryFilteredData.length).fill(false);
                //  setCategoryTestCartFilterVar(selectCategoryTestCartVar);
            }
        }
    }, [props.cart])
    console.log("CategoryFilteredData ==", categoryFilteredData);

    const history = useHistory();
    const nextPath = (path, data) => {
        history.push({
            pathname: path,
            state: data
        });
    };
    const handle_details = (data) => {
        nextPath(`/home/alltestsview/testdetails`, data);
    }

    console.log("poularTestFilter ====", props.popularTestFilter);

    console.log("searchFilterCategoryData ====", props.searchFilterCategoryData);

    return (

        <div >
        <div className="card-containers">
            {
                ((props.all_test?.response.length != 0) && (props.popularTestFilter == true)) ? (
                    <>
                        
                            {
                                (populartestData.length > 0) ? (populartestData.map((test, idx) => (

                                    <div className="cards" key={idx}
                                    // style={{backgroundColor:"blue"}}
                                    >
                                        <div className="card-bodys">
                                            <div className="doctor-widgets">
                                                <div className="doc-info-lefts">
                                                    <div className="doctor-imgs">
                                                        <div onClick={() => handle_details(test)}>
                                                            <img src={testImage} className="img-fluid" alt="test" />
                                                        </div>
                                                    </div>
                                                    <div className="doc-info-conts">
                                                        <h4 className="doc-names"><div onClick={() => handle_details(test)}>{test.name}</div></h4>
                                                        {/* <p className="doc-specialitys">{(test.short_description != null) ? test.short_description : "None"}</p> */}

                                                        <div className="clinic-detailss">

                                                            <div>


                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="doc-info-rights">
                                                    <div className="clini-infoss">
                                                        <ul>

                                                            <li>
                                                            {/* <i className="far fa-money-bill-alt">
                                                            </i> */}
                                                            {(test.cost != null) ? `₹ ${test.cost}` : "None"}
                                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{(test.cost != null) ? `₹ ${test.cost}` : "None"}</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        {/* <i className="fas fa-info-circle"></i> */}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="clinic-bookingss">
                                                        <button
                                                            onClick={() => handle_details(test)}
                                                            type="button"
                                                            className="btn btn-detail">Know More</button>


                                                        <button
                                                            onClick={() => handleCart(test, idx, "categoryFilterTest")}
                                                            //  className="apt-btn"
                                                            type="button"
                                                            className="btn btn-cart mt-1"
                                                        >{(true) ? `Add to Cart` : `Remove`}</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))) : (
                                    <div className="col-sm-6 col-md-4 col-xl-6">
                                        <h3><i>Popular tests yet to be added</i></h3>
                                    </div>
                                )

                            }
                        
                    </>
                ) : ((props.all_test?.response.length != 0) && (props.searchFilterCategoryData.length != 0)) ? (categoryFilteredData.map((test, idx) => (
                    
                        <div className="cards" key={idx}
                        // style={{backgroundColor:"blue"}}
                        >
                            <div className="card-bodys">
                                <div className="doctor-widgets">
                                    <div className="doc-info-lefts">
                                        <div className="doctor-imgs">
                                            <div onClick={() => handle_details(test)}>
                                                <img src={testImage} className="img-fluid" alt="test" />
                                            </div>
                                        </div>
                                        <div className="doc-info-conts">
                                            <h4 className="doc-names"><div onClick={() => handle_details(test)}>{test.name}</div></h4>
                                            {/* <p className="doc-specialitys">{(test.short_description != null) ? test.short_description : "None"}</p> */}

                                            <div className="clinic-detailss">

                                                <div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="doc-info-rights">
                                        <div className="clini-infoss">
                                            <ul>

                                                <li>
                                                {/* <i className="far fa-money-bill-alt">
                                                </i>  */}
                                                {(test.cost != null) ? `₹ ${test.cost}` : "None"}
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{(test.cost != null) ? `₹ ${test.cost}` : "None"}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            {/* <i className="fas fa-info-circle"></i> */}
                                                        </span>
                                                    </OverlayTrigger>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="clinic-bookingss">
                                            <button
                                                onClick={() => handle_details(test)}
                                                type="button"
                                                className="btn btn-detail">Know More</button>


                                            <button
                                                onClick={() => handleCart(test, idx, "categoryFilterTest")}
                                                //  className="apt-btn"
                                                type="button"
                                                className="btn btn-cart mt-1"
                                            >{(true) ? `Add to Cart` : `Remove`}</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                ))) :

                    (<>
                        {(props.all_test && (props.all_test?.response.length > 0)) ? (props.all_test?.response.map((test, idx) => (
                            <>
                            
                                    {(props.searchFilterData == null) ? (

                                       

                                        <div className="cards" key={idx}
                                        // style={{backgroundColor:"yellow"}}
                                        >
                                            <div className="card-bodys">
                                                <div className="doctor-widgets">
                                                    <div className="doc-info-lefts">
                                                        <div className="doctor-imgs">
                                                            <div onClick={() => handle_details(test)}>
                                                                <img src={testImage} className="img-fluid" alt="test" />
                                                            </div>
                                                        </div>
                                                        <div className="doc-info-conts">
                                                            <h4 className="doc-names"><div onClick={() => handle_details(test)}>{test.name}</div></h4>
                                                            {/* <p className="doc-specialitys">{(test.short_description != null) ? test.short_description : "None"}</p> */}

                                                            <div className="clinic-detailss">

                                                                <div>


                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="doc-info-rights">
                                                        <div className="clini-infoss">
                                                            <ul>

                                                                <li>
                                                                {/* <i className="far fa-money-bill-alt"></i>  */}
                                                                {(test.cost != null) ? `₹ ${test.cost}` : "None"}
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{(test.cost != null) ? `₹ ${test.cost}` : "None"}</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            {/* <i className="fas fa-info-circle"></i> */}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="clinic-bookingss">
                                                            <button
                                                                onClick={() => handle_details(test)}
                                                                type="button"
                                                                className="btn btn-detail">know more</button>


                                                            <button
                                                                onClick={() => handleCart(test, idx, "allTest")}
                                                                //  className="apt-btn"
                                                                type="button"
                                                                className="btn btn-cart "
                                                            >{(cartVar[idx] == false) ? `Add to cart` : `Remove`}</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    ) :



                                        (test.name.toLowerCase().includes(props.searchFilterData.toLowerCase())) ? (

                                            <div className="cards" key={idx}
                                            // style={{backgroundColor:"pink"}}
                                            >
                                                <div className="card-bodys">
                                                    <div className="doctor-widgets">
                                                        <div className="doc-info-lefts">
                                                            <div className="doctor-imgs">
                                                                <div onClick={() => handle_details(test)}>
                                                                    <img src={testImage} className="img-fluid" alt="test" />
                                                                </div>
                                                            </div>
                                                            <div className="doc-info-conts">
                                                                <h4 className="doc-names"><div onClick={() => handle_details(test)}>{test.name}</div></h4>
                                                                {/* <p className="doc-specialitys">{(test.short_description != null) ? test.short_description : "None"}</p> */}

                                                                <div className="clinic-detailss">

                                                                    <div>


                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="doc-info-rights">
                                                            <div className="clini-infoss">
                                                                <ul>

                                                                    <li><i className="far fa-money-bill-alt">
                                                                    </i> {(test.cost != null) ? `₹ ${test.cost}` : "None"}
                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{(test.cost != null) ? `₹ ${test.cost}` : "None"}</Tooltip>}>
                                                                            <span className="d-inline-block">
                                                                                {/* <i className="fas fa-info-circle"></i> */}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="clinic-bookingss">
                                                                <button
                                                                    onClick={() => handle_details(test)}
                                                                    type="button"
                                                                    className="btn btn-detail">Know More</button>


                                                                <button
                                                                    onClick={() => handleCart(test, idx, "allTest")}
                                                                    //  className="apt-btn"
                                                                    type="button"
                                                                    className="btn btn-cart mt-1"
                                                                >{(cartVar[idx] == false) ? `Add to Cart` : `Remove`}</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ) :
                                            null


                                    }
                                   
                            </>
                        ))) : (
                            <div className="col-sm-6 col-md-4 col-xl-6">
                                <h3><i>Tests yet to be added</i></h3>
                            </div>
                        )}

                    </>)

            }
            </div>
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        get_all_test: (data, callback) => dispatch(get_all_test_req(data, callback)),
        // get_all_test_call_back: (data,callback) =>dispatch(get_all_test_call_back_req(data,callback)),
        add_to_cart: (data, callback) => dispatch(add_to_cart_request(data, callback)),
        delete_from_cart: (data, callback) => dispatch(delete_from_cart_request(data, callback)),

    };
};
const mapStateToProps = (state) => {
    return {
        status: state.user.status,
        error: state.user.error,
        all_test: state.test.all_test,
        cart: state.cart.cartVal,
        user: state.user.user,
        isLoggedIn: state.user.isLoggedIn,
        requesting: state.user.requesting,


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchList);