import React from 'react';
import './whybookwithus.css'; // Import the updated CSS
import bookonline from "../../../assets/images/booking.png";
import homecollection from "../../../assets/images/home-delivery.png";
import report from "../../../assets/images/report.png";

const WhyBookWithUs = () => {
  const features = [
    {
      title: "Book Online",
      description: "Easy online booking system",
      image:bookonline
    },
    {
      title: "Home Sample Collection",
      description: "Convenient doorstep collection",
      image:homecollection
    },
    {
      title: "Accurate & Quick Reports",
      description: "Fast and reliable results",
      image:report
    },
  ];

  return (
    <div className="why-book-with-us">
      <h1 className="heading">Why Book with Us?</h1>

      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="icon-wrapper"> <img src={feature.image} className="whyusimage" alt="Promotional" /></div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyBookWithUs;
