import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

import "./sidebar.css";



const Sidebar = () => {
  const [isVisible, setIsVisible] = useState(false); // Sidebar visibility state

  const toggleSidebar = () => {
    setIsVisible(!isVisible); // Toggle visibility
  };

   const token = localStorage.getItem("token");
   console.log("tolen value is",token)

   
  


  return (
    <>
      <button className="toggle-button" onClick={toggleSidebar}>
        {isVisible ? "✖" : "☰"}
      </button>
      {isVisible && (
        <div className="sidebar">
          <div className="sidebar-item">
          <a href="/home/allTestsView/allTests" className="sidebar-link">
          <div className="sidebar-icon">📋</div>
          <div className="sidebar-text">Book a <br></br> Test</div>
            </a>
          </div>
          <div className="sidebar-item">
          <a href="https://maps.app.goo.gl/bq3kv83bsyzrTZUR9" target="_blank" rel="noopener noreferrer" className="sidebar-link">
            <div className="sidebar-icon">📍</div>
            <div className="sidebar-text">Find a <br></br> Centre</div>
            </a>
          </div>
         
          {token && (
            <div className="sidebar-item">
              <a href="/dashboard/Reports" className="sidebar-link">
                <div className="sidebar-icon text-center">⬇️</div>
                <div className="sidebar-text">
                  Download <br /> Report
                </div>
              </a>
            </div>
          )}

          <div className="sidebar-item">
          <a href="/home/allPackagesView/allPackages" className="sidebar-link">
          <div className="sidebar-icon text-center">💖</div>
          <div className="sidebar-text">Health <br></br> Packages</div>
            </a>
          </div>
          {/* <div className="sidebar-item">
            <div className="sidebar-icon">📱</div>
            <div className="sidebar-text">Book on <br></br> Call</div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Sidebar;
