import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DashboardSidebar } from "../sidebar/sidebar.jsx";
import { Tab, Tabs } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import { useHistory } from "react-router";
import Modal from '@material-ui/core/Modal';
import  * as ReactBootstrap from 'react-bootstrap';
import AddOrder from "./addorder/index.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {get_all_orders_req} from "../../../../redux-services/actions/action.order/action.function.js";
import {statusConstants} from "../../../../utils/constants";



function Orders(props) {
  const [openModal, setOpenModal] = React.useState(false);
  const { SearchBar, ClearSearchButton } = Search;
  const [ordersData, setOrdersData] = useState([]);
  const [noOrdersData, setNoOrdersData] = useState("");
  const history = useHistory();
  const nextPath = (path) => {
      history.push(path);
    }
    const nextPathDataPush = (path,data) =>{
      props.history.push({pathname: path,state: {
      data: data,
      }
    })
    }
    


    const getOrdersApiCall = async() =>{
    
      await props.get_all_orders(null,(error,success)=>{
       if(error){
         
         console.log("error",error);
       }    
       if(success.status == 200 ){
         if (success && success?.response.length > 0)
          console.log("sorted data",success.response); {
          const sortedOrders = success.response.sort((a, b) => {
        
          });
      
          // Update state with sorted orders
          setOrdersData(sortedOrders);
         }
         if (success && success?.response.length <= 0) {
           console.log("data length empty",success?.message);
           setNoOrdersData(success?.message);
         }
        }
        });
       
   
     }
     useEffect(() => {
       getOrdersApiCall();
     }, []);

     console.log("orders data ==", ordersData);
    const handle_view =  (id,row) => {
      console.log("view id passed ====",id);
        var data = {
          "orderid":`${id}`,
           "orderdata":row
        }
     
          nextPathDataPush(`/Orders/OrderView`,data);
      
      
    };



    const handleOpen = () => {
      setOpenModal(true);
    };
  
    const handleClose = () => {
      setOpenModal(false);
    };


    



    const orderColumns = [ {
      dataField: "orderid",
      text: "Order ID",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return <div> {row.orderid ? row.orderid : null}</div>;
      }
    },
    
    {
      dataField: "patient_first_name",
      text: "Patient Name",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return <div> {row.patient_first_name ? `${row.patient_first_name} ${row.patient_last_name}` : null}</div>;
      }
    },
  
    {
      dataField: "coupon_name",
      text: "Coupon Name",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return <div> {row.coupon_name ? row.coupon_name : "NONE"}</div>;
      }
    },
    {
      dataField: "totalcost",
      text: "Total Cost (₹)",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return <div> {row.totalcost ? row.totalcost : null}</div>;
      }
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return <div> {row.payment_method ? row.payment_method : null}</div>;
      }
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        console.log("row values_status ==", statusConstants);
        return (
          <div className={(row.status == statusConstants.raised) ? "text-primary" : (row.status == statusConstants.assigned) ? "text-secondary": (row.status == statusConstants.processed) ? "text-secondary": (row.status == statusConstants.closed) ? "text-success": (row.status == statusConstants.declined)? "text-warning": "text-danger"}>
            <strong>
              {row.status != null
                ? row.status == statusConstants.raised
                  ? "Raised"
                  : row.status == statusConstants.assigned
                  ? "Technician Assigned"
                  : row.status == statusConstants.processed
                  ? "Processed"
                  : row.status == statusConstants.closed
                  ? "Completed"
                  : row.status == statusConstants.declined
                  ? "Declined"
                  : "Cancelled"
                : "Nill"}
            </strong>
          </div>
        );
      }
    },
  
    {
      dataField: "link",
      text: "Action",
      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
         
          // <div className="btn-group w-0 p-0">
          //   <button
          //     type="button"
          //     className="btn btn-info text-white mr-2"
          //     //  onClick={() => handleViewClick(row.orderid,row)}
          //   >
          //     <i className="fa fa-eye"></i>
          //   </button>
          // </div>

          <div className="table-action">
          <button type="button" className="btn btn-sm bg-info-light" onClick={() =>handle_view(row.orderid,row)}>
              <i className="far fa-eye"></i> View
            </button>

            {/* <button type="button" className="btn btn-sm bg-primary-light">
              <i className="fas fa-trash-alt"></i> Cancel
            </button> */}
          </div>        
        );
      }
    }
  ];
  
  
    
  
  
  
  
  
      const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
          console.log('page', page);
          console.log('sizePerPage', sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
          console.log('page', page);
          console.log('sizePerPage', sizePerPage);
        }
      });
    



    return (
      <div className="content">
        <div className="container-fluid">
       <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar {...props}/>
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
              <div className="card-body user-tabs">
                <div className="row family-row">
                <div className="mr-auto p-1">
                <strong> Orders </strong>
                  </div>
                  <div className="" >
                {/* <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleOpen}
                    // onClick={() =>
                    //   nextPath("/Package/AddPackage")
                    // }
                    // onClick={handleModalClick}
                  >
                    <span >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </span>
                    <span>Add Orders</span>
                  </button>
                </div> */}
              </div>
                  </div>
                  
                      <div className="card card-table mb-0 mt-2">
                        <div className="card-body">
                          <div className="table-responsive">
                           




                           <ToolkitProvider
                             bootstrap4
                             keyField='id'
                             data={ordersData}
                             columns={orderColumns}
                             search
                           > 
       
      {
          props => (
            <div>
              {/* <h6>Input something at below input field:</h6> */}
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable striped bordered hover
                // defaultSorted={defaultSorted}
                pagination={pagination}
                noDataIndication={() => (
                                          <div className="text-alert">
                                             <i>{noOrdersData}</i>
                                          </div>
                                    )}
                {...props.baseProps}
              />
              </div>
          )
        }
      </ToolkitProvider>
                            
                          </div>
                        </div>
                      </div>
                    
                    
                   
                
                </div> 
              </div>
            </div>
          </div>

          {/* <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="mx-auto"
      >
        <AddOrder/>
         </Modal> */}



        </div>
      </div>
    );
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      //  logout_req: () =>dispatch(logout_req()),
      //  user_by_id_req: (data,callback) =>dispatch(get_user_by_id_req(data,callback)),
      get_all_orders: (data,callback) =>dispatch(get_all_orders_req(data,callback))
    };
    };
    const mapStateToProps = (state) => {
    return {
      status: state.user.status,
      error: state.user.error,
      user_by_id:state.user.user_by_id,
      user: state.user.user,
      isLoggedIn: state.user.isLoggedIn,
      requesting: state.user.requesting,
    
    
    };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(Orders);
