import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import IMG01 from "../../../../assets/images/placeholderImg300x300.png";
import "./checkboxStyle.css";
import SelectPatient from "../../bookings/selectPatient/index";
import SelectAddress from "../../bookings/selectAddress/index";
import UserBooking from "../../bookings/booking/index";
import { refresh_cart_request } from "../../../../redux-services/actions/action.cart/action.function.js"
import { create_order_request, verify_order_request } from "../../../../redux-services/actions/action.order/action.function.js";
import testImage from "../../../../assets/images/tests.png";
import packagesImage from "../../../../assets/images/packages.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { get_all_family_member_req } from "../../../../redux-services/actions/action.familymember/action.function.js";
import { get_all_my_address_req } from "../../../../redux-services/actions/action.myaddress/action.function.js";
import { verify_coupon_by_id_request } from "../../../../redux-services/actions/action.order/action.function.js";
import { get_all_coupons_req, get_delivery_charges_req } from "../../../../redux-services/actions/action.order/action.function.js";

import AddFamilyMember from "../../../users/dashboard/familyMembers/addFamilyMember/index.js";
import AddAddress from "../../../users/dashboard/myAddress/addAdress/index.jsx";
// import uploadPrescription from "../../dashboard/uploadPrescription";
import { CONSTANAT_MESSAGES, paymentMethod, paymentMethodCancellApply } from "../../../../utils/constants";
import toaster from "../../../../utils/ReactTostify";
import { API_URL, RAZORPAY_KEY_ID } from "../../../../redux-services/api/api.js";
import { API } from '../../../../utils/networkApi.js';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
//  import {upload_prescription_req,upload_prescription_success} from "../../../../redux-services/actions/action.dashboard/action.function.js";
import dashboard from "../../dashboard";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { API_URL_ROOT } from "../../../../redux-services/api/api.js";
import { get_all_prescriptions_req } from "../../../../redux-services/actions/action.dashboard/action.function.js";
import UploadPrescription from "../../dashboard/uploadPrescription";
import { upload_prescription_req } from "../../../../redux-services/actions/action.dashboard/action.function.js";
// import {add_to_cart_request,delete_from_cart_request} from "../../../../../redux-services/actions/action.cart/action.function.js"
import { add_to_cart_request, delete_from_cart_request } from "../../../../redux-services/actions/action.cart/action.function.js";
// import {isLoading} from '../../redux-services/actions/action.loader/action.function.js';
import { isLoading } from "../../../../redux-services/actions/action.loader/action.function.js";
import Loader from "../../../../utils/spin_loader.js";



import { Option } from "antd/lib/mentions";
function UserCheckout(props) {
  const { SearchBar, ClearSearchButton } = Search;
  const [loading, setLoading] = useState(false);
  console.log("loading@@@@", loading)
  const [familyMemberData, setFamilyMemberData] = useState([]);
  const [addFamilymemberButton, setAddFamilymemberButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectoptionsFamilyMember, setSelectoptionsFamilyMember] = useState(
    []
  );
  const [optionValueFamilyMember, setOptionValueFamilyMember] = useState([]);

  const [myAddressData, setMyAddressData] = useState([]);
  const [myAddressArea, setMyAddressArea] = useState("");
  const [addMyAddressButton, setAddMyAddressButton] = useState(false);
  const [openModalAddress, setOpenModalAddress] = useState(false);
  const [selectoptionsMyAddress, setSelectoptionsMyAddress] = useState([]);
  const [optionValueMyAddress, setOptionValueMyAddress] = useState([]);

  //  const[prescription_photo,setPrescription_photo]=useState(null);

  //  const prescription_photo=null;

  const [couponData, setCouponData] = useState([]);

  const [couponShow, setCouponShow] = useState(false);
  const [openModalCoupon, setOpenModalCoupon] = useState(false);
  const [selectoptionsCoupon, setSelectoptionsCoupon] = useState([]);
  const [optionValueCoupon, setOptionValueCoupon] = useState([]);
  const [discountValueCoupon, setDiscountValueCoupon] = useState("");
  const [showcountValueCoupon, setShowcountValueCoupon] = useState("");
  const [discountAmount, setdiscountAmount] = useState("");



  console.log("hisunil!!", optionValueCoupon.discount_value);
  console.log("hisuni@@", discountValueCoupon);
  console.log("hisunil%%", showcountValueCoupon);

  const [finalAmount, setFinalAmount] = useState(props.total_cart_value);
  console.log("finalAmount", finalAmount)
  const [discountNameCoupon, setDiscountNameCoupon] = useState("");
  console.log("check the value is",discountNameCoupon);
  const [discountCouponId, setDiscountCouponId] = useState(null);
  const [couponValidateErrorMessage, setCouponValidateErrorMessage] = useState("");
  const [couponValidateState, setCouponValidateState] = useState(false);
  console.log("coupon state", couponValidateState)
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [timeSlotDayUpdate, setTimeSlotDayUpdate] = useState("");
  console.log("check the vlaue1",timeSlotDayUpdate);
  const [timeSlotUpdate, setTimeSlotUpdate] = useState("");
  console.log("check the vlaue2",timeSlotUpdate);

  const [paymentMethodState, setPaymentMethodState] = useState("");

  const [serviceIdArray, setServiceIdArray] = useState([]);
  const [packageIdArray, setPackageIdArray] = useState([]);
  const [prescriptionArray, setPrescriptionArray] = useState([]);

  const [prescriptionRequiredState, setPrescriptionRequiredState] = useState(false);
  const [prescriptionRequiredFile, setPrescriptionRequiredFile] = useState(null);
  const [prescriptionRequiredFileName, setPrescriptionRequiredFileName] = useState("");
  const [prescriptionRequiredSelectState, setPrescriptionRequiredSelectState] = useState(false);
  const [prescriptionsData, setPrescriptionsData] = useState([]);
  const [noPrescriptionsData, setNoPrescriptionsData] = useState("");
  const [imgUploadPrescriptionStatus, setImgUploadPrescriptionStatus] = useState(false);
  const [handleTermsConditionClickState, setHandleTermsConditionClickState] = useState(false);
  console.log("prescriptionRequiredFileName!!!", prescriptionRequiredFile);
  console.log("prescriptionRequiredFileName@@", prescriptionRequiredFileName)

 

  const [filteredAddressOptions, setFilteredAddressOptions] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState();

  const [paymentModeButtonState, setPaymentModeButtonState] = useState(false);

  //disable button
  const [remove_coupon, setRemove_coupon] = useState(false);
  console.log("remove_coupon", remove_coupon)
  const [apply_coupon, setApply_coupon] = useState(true);
  const [select_coupon_button, setSelect_coupon_button] = useState(true);
  //cart
  const [cart, setCart] = useState(props.cart);

  const [cartLength, setcartLength] = useState(cart.length);

  const [totalAmount, setTotalAmount] = useState(props.total_cart_value);
  const [cartVar, setCartVar] = useState([]);

  const [couponstate, setcouponState] = useState(false);
  const [amountstate, setAmountState] = useState(false);





  console.log("total amouny is", totalAmount)



  // console.log("discountValueCoupon", discountValueCoupon);
  // console.log("showcountValueCoupon", showcountValueCoupon);
  // const [submitButton, setSubmitButton] = useState(false);
  const [uploadPrescriptionModal, setUploadPrescriptionModal] = useState(false);
  console.log("values submission@@@@@@@@@@@@@@@@@@@@@@@@@@", props)
  const prescriptionName = localStorage.getItem("prescription_name");
  console.log("prescriptionName", prescriptionName)
  const prescriptionPhoto = localStorage.getItem("prescription_photo");
  console.log("prescriptionPhoto", prescriptionPhoto)

  const getFamilyMembersApiCall = async () => {
    await props.get_all_family_member(null, (error, success) => {
      if (error) {
        console.log("error", error);
      }
      if (success.status == 200) {


        if (success && success?.response.length > 0) {
          setFamilyMemberData(success?.response);
          const options =
            success?.response.length > 0
              ? success.response.map((familyMemberSelect, idx) => ({
                patient_id: familyMemberSelect.patient_id,
                patient_name: `${familyMemberSelect.patient_firstname} ${familyMemberSelect.patient_lastname}`
              }))
              : null;
          setSelectoptionsFamilyMember(options);
        }
        if (success && success?.response.length <= 0) {
          console.log("data length empty", success?.message);
          setAddFamilymemberButton(true);
        }
      }
    });
  };

  const getMyAddressApiCall = async () => {
    await props.get_all_my_address(null, (error, success) => {
      if (error) {
        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {
          setMyAddressData(success?.response);
          const optionsAddress =
            success?.response.length > 0
              ? success.response.map((myAddressSelect, idx) => ({
                address_id: myAddressSelect.address_id,
                patient_id: myAddressSelect.patientid,
                address: `No ${myAddressSelect.house_number} ${myAddressSelect.street_name} ${myAddressSelect.area} ${myAddressSelect.landmark} ${myAddressSelect.city} ${myAddressSelect.state} ${myAddressSelect.pincode}`
              }))
              : null;
          setSelectoptionsMyAddress(optionsAddress);
        }
        if (success && success?.response.length <= 0) {
          console.log("data length empty", success?.message);
          setAddMyAddressButton(true);
        }
      }
    });
  };



  // const getCouponApiCall = async () => {
  //   await props.get_all_coupons(null, (error, success) => {
  //     if (error) {
  //       console.log("error", error);
  //     }
  //     if (success.status == 200) {
  //       if (success && success?.response.length > 0) {
  //         setCouponData(success?.response);
  //         success?.response.forEach(coupon => {
  //           if (coupon.discount_type === "percentage") {
  //             // Multiply discount_value by 10 when discount_type is "percentage"
  //             const discountAmount = coupon.discount_value * 10;
  //             setDiscountValueCoupon(discountAmount);
  //           }});

  //         setCouponShow(true);
  //         const options =
  //           success?.response.length > 0

  //             ? success.response.map((couponSelect, idx) => ({

  //               coupon_id: couponSelect.couponid,
  //               coupon_name: couponSelect.coupon_name,
  //                discount_value: couponSelect.discount_value

  //             }))
  //             : null;

  //         setSelectoptionsCoupon(options);
  //       }
  //       if (success && success?.response.length <= 0) {
  //         console.log("data length empty", success?.message);
  //         setCouponShow(false);
  //         //setAddCouponButton(true);
  //       }
  //     }
  //   });
  // };

  const getCouponApiCall = async () => {
    await props.get_all_coupons(null, (error, success) => {
      if (error) {
        console.log("error", error);
      }
      if (success.status === 200) {
        if (success && success?.response.length > 0) {
          setCouponData(success?.response);
          console.log("getCouponApiCall", couponData);


          //  let CouponAmount= success?.response.forEach(coupon => {
          //     if (coupon.discount_type === "percentage") {
          //       // Multiply discount_value by 10 when discount_type is "percentage"
          //       const discountAmount = coupon.discount_value * 10;
          //       setDiscountValueCoupon(discountAmount);
          //       console.log("discountdata",discountAmount);
          //     }


          //   });

          setCouponShow(true);

          const options =
            success?.response.length > 0
              ? success.response.map((couponSelect, idx) => ({
                coupon_id: couponSelect.couponid,
                coupon_name: couponSelect.coupon_name,
                discount_value: couponSelect.discount_value,
                discount_type: couponSelect.discount_type,
              }))
              : null;
          setSelectoptionsCoupon(options);
        }
        if (success && success?.response.length <= 0) {
          console.log("data length empty", success?.message);
          setCouponShow(false);
          //setAddCouponButton(true);
        }
      }
    });
  };


  const getPrescriptionsApiCall = async () => {

    await props.get_all_prescriptions(null, (error, success) => {
      if (error) {

        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {
          setPrescriptionsData(success?.response);
        }
        if (success && success?.response.length <= 0) {
          console.log("data Prescriptions length empty ", success?.message);
          setNoPrescriptionsData(success?.message);
        }
      }
    });


  }

  const getDeliveryChargesApiCall = async () => {

    await props.get_delivery_charges(null, (error, success) => {
      if (error) {

        console.log("error", error);
      }
      if (success.status == 200) {
        if (success && success?.response.length > 0) {

          setDeliveryFee(success?.response[0].customer_delivery_charge);
        }
        if (success && success?.response.length <= 0) {
          console.log("data DeliveryFee length empty ", success?.message);
          setDeliveryFee(0);
        }
      }
    });


  }

  const serviceidsArray = [];
  const packageidsArray = [];

  const cartValueSegregate = () => {
    console.log("hitting cart value");

    if (props.cart.length > 0) {
      let filteredService = props.cart.filter(
        (cartItemVal) => cartItemVal.serviceid
      );

      filteredService.forEach((serviceItem, idx) => {
        serviceidsArray.push({ service_id: serviceItem.serviceid });

      });
      setServiceIdArray(serviceidsArray);

      let filteredPopularService = props.cart.filter(
        (cartItemVal) => cartItemVal.id
      );
      filteredPopularService.forEach((servicePopularItem, idx) => {
        serviceidsArray.push({ service_id: servicePopularItem.id });

      });
      setServiceIdArray(serviceidsArray);

      let filteredPackage = props.cart.filter(
        (cartItemVal) => cartItemVal.package_id
      );
      filteredPackage.forEach((packageItem, id) => {
        packageidsArray.push({ package_id: packageItem.package_id });

      });
      setPackageIdArray(packageidsArray)


    }
  };

  const cartPrescriptionArray = [];
  const cart_prescription = () => {
    console.log("cart item values ====", props.cart);
    props.cart.forEach((cartItem, id) => {
      if (cartItem.prescription == 1) {
        cartPrescriptionArray.push({ "`prescription${id}`": cartItem.prescription });
      }


    });
    setPrescriptionArray(cartPrescriptionArray);

  }

  useEffect(() => {
    if ((props.user?.userId != null) || (props.user?.user_by_id != null)) {
      getFamilyMembersApiCall();
      getMyAddressApiCall();
      getDeliveryChargesApiCall();



      if (props.cart.length > 0) {
        getCouponApiCall();
        cart_prescription();
        getPrescriptionsApiCall();


      }

      cartValueSegregate();
    }

  }, []);
  const history = useHistory();

  const handleSuccess = (data) => {
    console.log("hi order")
    history.push({
      pathname: "/bookings/UserbookingSuccess",
      state: data
    });
  };
  const handleSelectTest = () => {
    history.push({
      pathname: "/home/allTestsView/allTests",
 
    });

  };
  const handleLogin = () => {
    history.push({
      pathname: "/home/login",
    });
  }

  // const handleBack = () => {
  //   props.history.goBack();

  //   props.reloadAddress();
  // };

  const reloadDataModal = async () => {
    await getFamilyMembersApiCall();
    await setOpenModal(!openModal);
    await setAddFamilymemberButton(false);
    await getPrescriptionsApiCall()
  };

  const reloadDataModalPrescription = async () => {
    await getPrescriptionsApiCall();
    await getPrescriptionsApiCall();

  }
  const handleModalClick = () => setOpenModal(!openModal);

  const reloadDataModalAddress = async () => {
    await getMyAddressApiCall();

    await setOpenModalAddress(!openModalAddress);
    await setAddMyAddressButton(false);
  };

  const handleModalAddressClick = () => setOpenModalAddress(!openModalAddress);

  const handleModalCouponClick = () => {
    setRemove_coupon(true)
    setSelect_coupon_button(false)
    setApply_coupon(false);
    // setRemove_coupon(false);
    // setDiscountValueCoupon(props.discount_value);
    setShowcountValueCoupon(showcountValueCoupon);
    setOpenModalCoupon(!openModalCoupon);
  };


  //  const handleModalPrescriptionClick = () => setPrescriptionRequiredState(!prescriptionRequiredState);

  const validate = Yup.object({
    patient: Yup.string()
      .required("Patient must not be empty"),
    address: Yup.string().required("Address must not be empty"),
    timeslotId: Yup.string().required("Time Slot must not be empty"),
    // prescription_name: Yup.string().required('Prescription name must not be empty'),

    //  coupon: Yup.string().nullable(true),
    // coupon: Yup.string().required("payment method is required"),
    paymentMethod: Yup.string().required("payment method is required"),
    termsandcondition: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions"),
    // (cart.length > 0): toaster('error',"add a item in cart")

  });

  // console.log("packageIdArray values", packageIdArray);
  // console.log("serviceIdArray values", serviceIdArray);
  // console.log("packageIdArray of values", packageidsArray);
  // console.log("serviceIdArray of values", serviceidsArray);
  console.log("cart item values ====", props.cart);
  const handleTermsConditionClick = (event) => {


    if (handleTermsConditionClickState == true) {
      setHandleTermsConditionClickState(false);
      formik.setFieldValue("termsandcondition", false);
    }
    if (handleTermsConditionClickState == false) {
      setHandleTermsConditionClickState(true);
      formik.setFieldValue("termsandcondition", true);
    }


  }
  

  const formik = useFormik({
    initialValues: {
      patient: "",
      address: "",
      address_id: "",
      timeslotId: "",
      coupon: "",
      paymentMethod: "",
      termsandcondition: false,
      prescription_photo: " "
    },
    validationSchema: validate,

    onSubmit: async (values) => {
      isLoading(true);
      setLoading(true);
      // setSubmitButton(true);
      console.log("values submission", values);
      console.log("user id ==", props.user.userId);
      var dt = formik.values;

      // "user_id":"3",
      // "patient_id":"67",
      // "coupon_id":"6",
      // "timeslot_id":"3",
      // "order_package":[{"package_id":"6"},{"package_id":"7"}],
      // "order_service":[{"service_id":"3"}],
      // "mobile":"9480364546",
      // "totalcost":"1500.00",
      // "address":"shimoga",
      // "payment_method":"COD"/"Online"
      console.log("the value is@@ ", props.user_by_id?.pamount);
      console.log("the value is@@@@finalAmount", finalAmount);
      console.log("the value is@@@@@@@@@totalCost ", totalCost);



      var jsondata = {
        // user_id: props.user.userId,
        patient_id: dt.patient,
        address: dt.address,
        address_id: dt.address_id,
        timeslot_id: dt.timeslotId,
        timestamp: timeSlotDayUpdate,
        coupon_id: discountCouponId !== null ? discountCouponId : null,
        payment_method: dt.paymentMethod,
        mobile: props.user.mobile,
        prescription_name: dt.prescription_name,
        prescription_photo: dt.prescription_photo,
        order_package: packageIdArray,
        order_service: serviceIdArray,
        // totalcost: ((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
        //  totalCost+ props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon :
        //   (props.user_by_id?.pamount > 0) ?
        //   totalCost + props.user_by_id?.pamount + parseInt(deliveryFee) :
        //     (discountValueCoupon !== "") ? totalCost + parseInt(deliveryFee) - discountValueCoupon :
        //     totalCost + parseInt(deliveryFee),


        totalcost: finalAmount === null || finalAmount === undefined
          ? (
            (props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")
              ? totalCost + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon
              : (props.user_by_id?.pamount > 0)
                ? totalCost + props.user_by_id?.pamount + parseInt(deliveryFee)
                : (discountValueCoupon !== "")
                  ? totalCost + parseInt(deliveryFee) - discountValueCoupon
                  : totalCost + parseInt(deliveryFee)
          )
          : finalAmount + parseInt(deliveryFee),
        orderamount: finalAmount === null || finalAmount === undefined
          ? (
            (props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")
              ? totalCost + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon
              : (props.user_by_id?.pamount > 0)
                ? totalCost + props.user_by_id?.pamount + parseInt(deliveryFee)
                : (discountValueCoupon !== "")
                  ? totalCost + parseInt(deliveryFee) - discountValueCoupon
                  : totalCost + parseInt(deliveryFee)
          )
          : finalAmount,

        // totalcost:((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
        // discountValueCoupon +  props.user_by_id?.pamount: props.total_cart_value + deliveryFee,

        // totalcost:props.total_cart_value,
        // orderamount: props.total_cart_value,
        // orderamount: props.total_cart_value ,

        discountamount: (discountValueCoupon !== "") ? discountValueCoupon : 0,
        pamount: props.user_by_id?.pamount,
        // prescription_photo:prescription_photo,
        // prescription_name:"",

      };

      const totalCostValue = amountstate ? parseFloat(finalAmount) : parseFloat(jsondata.totalcost);

      // console.log("values submission jsonData", orderamount);
      // console.log("values submission jsonData", discountamount);
      // console.log("values submission jsonData", pamount);
      console.log("values submission jsonData", totalCostValue);
      const data = new FormData();
      
      data.append('patient_id', dt.patient);
      data.append('address', dt.address);
      data.append('area', myAddressArea);
      data.append('address_id', dt.address_id);
      data.append('timeslot_id', dt.timeslotId);
      data.append('timestamp', timeSlotDayUpdate);
      data.append('coupon_id', discountCouponId !== null ? discountCouponId : null);
      data.append('payment_method', dt.paymentMethod);
      data.append('mobile', props.user.mobile);
      data.append('order_package', packageIdArray.length > 0 ? JSON.stringify(packageIdArray) : null);
      data.append('order_service', serviceIdArray.length > 0 ? JSON.stringify(serviceIdArray) : null);
      // data.append('totalcost', parseFloat(jsondata.totalcost));
      data.append('totalcost', parseFloat(totalCostValue));
      
      data.append('upload_prescription', prescriptionRequiredFile);
      data.append('orderamount', parseFloat(jsondata.orderamount));
      data.append('discountamount', parseFloat(jsondata.discountamount));
      data.append('pamount', jsondata.pamount);
      data.append('prescription_name', prescriptionName);
      data.append('prescription_photo', prescriptionPhoto);
      // data.append('prescription_photo',null);


      // data.append('prescription_name',"");

      // data.append('prescription_name'.dt.prescription_name);
      // await props.upload_prescription(data,(error,success)=>{
      //   if(error){

      //     console.log("error",error);
      //   }    
      //   if(success.status == 200 ){
      //     toaster("success", success.message);
      //     handleBack();

      //   }
      //    });



      if (props.cart.length > 0) {
        // if(prescriptionArray.length > 0) {
        //   setPrescriptionRequiredState(true);
        //   if(dt.paymentMethod === "ONLINE" ){

        //     await props.create_order(data, (error, success) => {
        //       if(error) {
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         var options = {
        //           "key": RAZORPAY_KEY_ID, // Enter the Key ID generated 
        //           "amount": `${success.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        //           "currency": `${success.currency}`,
        //           "name": "PadmaShree Diagnostics",
        //           "description": "Thank you for your payment. Please give us some time",
        //           "image": `${API_URL.LOGO}`,
        //           "order_id": `${success.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        //           "handler": function (response){
        //             if(response) {
        //               const data = {
        //                 "order_id":`${response.razorpay_order_id}`,
        //                 "payment_id":`${response.razorpay_payment_id}`,
        //                 "razorpay_signature":`${response.razorpay_signature}`
        //               }

        //                props.verify_order(data, (error, success) => {
        //                 if(error) {
        //                   console.log("error", error);
        //                 }
        //                 if(success){
        //                   if(success.signatureIsValid === "true"){
        //                     var dataSent = {
        //                       order_id:`${response.razorpay_order_id}`,
        //                       timestamp: timeSlotDayUpdate
        //                     }
        //                     props.refresh_cart();
        //                     handleSuccess(dataSent);


        //                   }
        //                   if(success.signatureIsValid === "false"){
        //                     console.log("false success from razorpay",success);
        //                   }
        //                 }
        //               })
        //             }

        //           },
        //           "prefill": {
        //               "name": `${props.user_by_id?.response[0].first_name} ${props.user_by_id?.response[0].last_name}`,
        //               "email": `${props.user_by_id?.response[0].email}`,
        //               "contact": `${props.user_by_id?.response[0].mobile}`
        //           },
        //           // "notes": {
        //           //     "address": "Razorpay Corporate Office"
        //           // },
        //           "theme": {
        //               "color": "#3065b5"
        //           }
        //       };
        //       var razor = new window.Razorpay(options);
        //         razor.open();
        //       }
        //     });
        //   }
        //   if(dt.paymentMethod === "COD"){
        //     await props.create_order(data, (error, success) => {
        //       if(error){
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         props.refresh_cart();
        //         let dataSentCOD = {
        //           order_id:`${success.orderid}`,
        //           timestamp: timeSlotDayUpdate
        //         }
        //         handleSuccess(dataSentCOD);
        //       }
        //     });

        //   }






        // }

        // else{
        //   if(dt.paymentMethod === "ONLINE" ){

        //     await props.create_order(data, (error, success) => {
        //       if(error) {
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         var options = {
        //           "key": RAZORPAY_KEY_ID, // Enter the Key ID generated 
        //           "amount": `${success.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        //           "currency": `${success.currency}`,
        //           "name": "PadmaShree Diagnostics",
        //           "description": "Thank you for your payment. Please give us some time",
        //           "image": `${API_URL.LOGO}`,
        //           "order_id": `${success.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        //           "handler": function (response){
        //             if(response) {
        //               const data = {
        //                 "order_id":`${response.razorpay_order_id}`,
        //                 "payment_id":`${response.razorpay_payment_id}`,
        //                 "razorpay_signature":`${response.razorpay_signature}`
        //               }

        //                props.verify_order(data, (error, success) => {
        //                 if(error) {
        //                   console.log("error", error);
        //                 }
        //                 if(success){
        //                   if(success.signatureIsValid === "true"){
        //                     var dataSent = {
        //                       order_id:`${response.razorpay_order_id}`,
        //                       timestamp: timeSlotDayUpdate
        //                     }
        //                     props.refresh_cart();
        //                     handleSuccess(dataSent);


        //                   }
        //                   if(success.signatureIsValid === "false"){
        //                     console.log("false success from razorpay",success);
        //                   }
        //                 }
        //               })
        //             }

        //           },
        //           "prefill": {
        //               "name": `${props.user_by_id?.response[0].first_name} ${props.user_by_id?.response[0].last_name}`,
        //               "email": `${props.user_by_id?.response[0].email}`,
        //               "contact": `${props.user_by_id?.response[0].mobile}`
        //           },
        //           // "notes": {
        //           //     "address": "Razorpay Corporate Office"
        //           // },
        //           "theme": {
        //               "color": "#3065b5"
        //           }
        //       };
        //       var razor = new window.Razorpay(options);
        //         razor.open();
        //       }
        //     });
        //   }
        //   if(dt.paymentMethod === "COD"){
        //     await props.create_order(data, (error, success) => {
        //       if(error){
        //         console.log("error", error);
        //       }
        //       if(success.status == 200){
        //         // handleBack();
        //         console.log("success",success);
        //         props.refresh_cart();
        //         let dataSentCOD = {
        //           order_id:`${success.orderid}`,
        //           timestamp: timeSlotDayUpdate
        //         }
        //         handleSuccess(dataSentCOD);
        //       }
        //     });

        //   }


        // }

        if (dt.paymentMethod === "ONLINE") {

          await props.create_order(data, (error, success) => {
            // setSubmitButton(false);
            if (error) {
              console.log("error", error);
            }
            if (success.status == 200) {
              // handleBack();
              console.log("success", success);
              var options = {
                "key": RAZORPAY_KEY_ID, // Enter the Key ID generated 
                "amount": `${success.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": `${success.currency}`,
                "name": "PadmaShree Diagnostics",
                "description": "Thank you for your payment. Please give us some time",
                "image": `${API_URL.LOGO}`,
                "order_id": `${success.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function (response) {
                  if (response) {
                    const data = {
                      "order_id": `${response.razorpay_order_id}`,
                      "payment_id": `${response.razorpay_payment_id}`,
                      "razorpay_signature": `${response.razorpay_signature}`,
                      "userid": `${props.user.userId}`,
                    }

                    await props.verify_order(data, (error, success) => {
                      if (error) {
                        console.log("error", error);
                      }
                      if (success) {
                        if (success.signatureIsValid === "true") {
                          var dataSent = {
                            order_id: `${response.razorpay_order_id}`,
                            timestamp: timeSlotDayUpdate
                          }
                          props.refresh_cart();
                          handleSuccess(dataSent);


                        }
                        if (success.signatureIsValid === "false") {
                          console.log("false success from razorpay", success);
                          toaster('error', `${success.message}`);


                        }
                      }
                    })
                  }

                },
                "prefill": {
                  "name": `${props.user_by_id?.first_name} ${props.user_by_id?.last_name}`,
                  "email": `${props.user_by_id?.email}`,
                  "contact": `${props.user_by_id?.mobile}`
                },
                // "notes": {
                //     "address": "Razorpay Corporate Office"
                // },
                "theme": {
                  "color": "#3065b5"
                },
                "modal": {
                  "escape": false,
                  "ondismiss": async function () {
                    // console.log('Checkout form closed');
                    let data = {
                      "order_id": `${success.razorpay_order_id}`
                    }

                    const result = await API.post(API_URL.ORDER_FAILED, data, API.getHeaders());
                    let response = result.data;
                    console.log("response values of order failed ", response);
                    if (response.status === 200) {
                      toaster('error', response.message);


                    }
                    // toaster('error', `Payment verification failed`);
                    // toaster('error', `${success.message}`);
                  }
                }
              };
              var razor = new window.Razorpay(options);
              razor.open();
            }
          });
        }
        if (dt.paymentMethod === "COD") {
          await props.create_order(data, (error, success) => {
            setLoading(true); // Show loader
            console.log("loader")
            console.log("error_message", error);
            console.log("error_message2")

            if (error) {

              setLoading(false);
              if (error.status == 404) {
                console.log("error_message1")
                toaster('error', "Failed to create order please try again");
              }
            }
            if (success.status == 200) {
              setLoading(false); // Hide loader
              console.log("hi order id")
              // handleBack();
              console.log("success", success);
              props.refresh_cart();
              let dataSentCOD = {
                order_id: `${success.orderid}`,
                timestamp: timeSlotDayUpdate
              }
              handleSuccess(dataSentCOD);
            }

            if (success === 412) {
              setLoading(false);
              toaster('error', `${success.message}`);
            }
          });

        }


      }



    },
    onReset: (values) => { }
  });

  const validateCoupon = async () => {


    if (discountNameCoupon !== null) {
      let data = {
        //  amount: parseFloat(props.total_cart_value ),
        amount: (parseFloat(props.total_cart_value) + parseFloat(deliveryFee)).toFixed(2),

        // amount:props.total_cart_value ,
        couponcode: discountNameCoupon

      };
      console.log("amount", props.total_cart_value);
      console.log("deliveryFee@@@@", deliveryFee);
      // setCouponValidateState(false);
      await props.verify_coupon_by_id(data, (error, success) => {
        // setDiscountValueCoupon("0")
        setApply_coupon(true);
        if (error) {
          console.log("error", error);
        }
        if (success.status == 200) {
          setAmountState(true)
          setRemove_coupon(false);
          setcouponState(true)
          // toaster('error', success.message);
           // setShowcountValueCoupon(success.final_amount);
          setFinalAmount(success.final_amount);
          setdiscountAmount( success.discount_amount);
          console.log("the final maount is @@@@@@@", success.discount_amount)
          setDiscountValueCoupon(optionValueCoupon.discount_value);


          if (success.message === CONSTANAT_MESSAGES.MAX_EXCEED) {
            setAmountState(false);
            console.log("hi akshay")
            setFinalAmount( (parseFloat(props.total_cart_value) + parseFloat(deliveryFee)).toFixed(2));
            setCouponValidateState(true);
            toaster('error', success.message);
            console.log("error_message", success.message)

            // toaster('error', response.message);
            // setCouponValidateErrorMessage(CONSTANAT_MESSAGES.MAX_EXCEED);
            toaster('error', `${CONSTANAT_MESSAGES.MAX_EXCEED}`);
          }
          if (success.message === CONSTANAT_MESSAGES.MIN_AMT_RQRED) {
            setAmountState(false);
            setDiscountValueCoupon(showcountValueCoupon);
            setFinalAmount( (parseFloat(props.total_cart_value) + parseFloat(deliveryFee)).toFixed(2));
            setCouponValidateState(true);
            setShowcountValueCoupon(showcountValueCoupon)

            // optionValueCoupon.discount_value
            // setCouponValidateErrorMessage(CONSTANAT_MESSAGES.MIN_AMT_RQRED);
            toaster('error', `${CONSTANAT_MESSAGES.MIN_AMT_RQRED}`);
          }

          if (success.message === CONSTANAT_MESSAGES.COUPON_EXPIRED) {
            setAmountState(false);
            setCouponValidateState(true);
            setShowcountValueCoupon(showcountValueCoupon);
            setDiscountValueCoupon(discountValueCoupon);
            setFinalAmount( (parseFloat(props.total_cart_value) + parseFloat(deliveryFee)).toFixed(2));
            // setCouponValidateErrorMessage(CONSTANAT_MESSAGES.COUPON_EXPIRED);
            toaster('error', `${CONSTANAT_MESSAGES.COUPON_EXPIRED}`);
            // toaster('success', `${CONSTANAT_MESSAGES.COUPON_EXPIRED}`);

          }

          if (success.message === CONSTANAT_MESSAGES.COUPON_SUCCESS) {
            setAmountState(false);
            // setDiscountValueCoupon(success.final_amount);
            // setRemove_coupon(true);
            toaster('success', `${CONSTANAT_MESSAGES.COUPON_SUCCESS}`);
          }


        }
      });
    }
  };
  //delete_coupon_name
  const delete_coupon_value = () => {
    setAmountState(false);
    setRemove_coupon(true);
    setDiscountNameCoupon("");
    setCouponValidateState(true);


  }
  const reloadPrecriptionDataModal = async () => {

    await setUploadPrescriptionModal(!uploadPrescriptionModal);
  }
  //

  console.log("formik values initialization  === ", formik.values);




  const handlePatient = (e) => {
    console.log("values of select patient ", e);
    
    formik.setFieldValue("patient", e.patient_id);
    setOptionValueFamilyMember(e.patient_id);
    filterAddressesByPatientId(e.patient_id);
    setSelectedPatientId(e.patient_id);
  };

  

  const filterAddressesByPatientId = (patientId) => {
    const filteredAddresses = selectoptionsMyAddress.filter(
      (address) => address.patient_id === patientId
    );
    setFilteredAddressOptions(filteredAddresses);
  };

  const handleAddress = (e) => {
    
    console.log("values of select address ", e);
    formik.setFieldValue("address", e.address);
    formik.setFieldValue("address_id", e.address_id);
    setOptionValueMyAddress(e);  

    const areaValue = myAddressData.map((addressValue, idx) => {
      if (addressValue.address_id == e.address_id) {
        return addressValue.area
      
      }
    })
    setMyAddressArea(areaValue);
  };

  const handleCoupon = (e) => {
    console.log("values of select coupon ", e);
    console.log("showcountValueCoupon ", showcountValueCoupon);
    // formik.setFieldValue("coupon", e.coupon_id);
    setDiscountCouponId(e.coupon_id);
    setOptionValueCoupon(e);
    //  setDiscountValueCoupon(e.discount_value);
    setShowcountValueCoupon(showcountValueCoupon);

    setOpenModalCoupon(!openModalCoupon);
    setDiscountNameCoupon(e.coupon_name);
  };
  console.log("appply coupon Value=======", discountValueCoupon);
  console.log("appply coupon Value=======!!!!", showcountValueCoupon);
  console.log("appply coupon Value=======", discountNameCoupon);



  console.log(" select day Value=======", timeSlotDayUpdate);

  console.log(" select time slot=======", timeSlotUpdate);




  const handleTimeslot = ({ dayValue, timeslotid }) => {
console.log("check the value hi hi ",dayValue)
    // debugger
    formik.setFieldValue("timeslotId", timeslotid);
    setTimeSlotDayUpdate(dayValue);
    // setTimeSlotUpdate(timeSlotIdUpdate);
  };
  // const handlePrescriptionUpload = (event) => {
  //   console.log("prescription required files uploaded", event.currentTarget.files[0]);
  //   setPrescription_photo(event.target.files[0])
  //   if (event.target.files.length !== 0) {
  //     setImgUploadPrescriptionStatus(true);
  //   var  prescription_photo = event.target.files[0];
  //     setPrescriptionRequiredFile(URL.createObjectURL(event.target.files[0]));
  //     setPrescriptionRequiredFileName(event.target.files[0].name)

  //   }

  // }
  const handleRadioOptn = (event) => {
    if (event.target.value == "Online Payment") {
      setPaymentMethodState(event.target.value);
      formik.setFieldValue("paymentMethod", "ONLINE");
      setPaymentModeButtonState(true);
    }
    if (event.target.value == "Cash On Delivery (COD)") {
      setPaymentMethodState(event.target.value);
      formik.setFieldValue("paymentMethod", "COD");
      setPaymentModeButtonState(false);
    }
  };

  console.log("payment method == ", paymentMethodState);
  // upload prescription
  // console.log("prescription_photo == ", prescription_photo);

  const handleModalPrescriptionClick = () => {
    // console.log("prescription_photo_with == ", prescription_photo);
    // const formData= { 
    //   'prescription_photo':prescription_photo,
    //   'prescription_name':"",
    // }

    setPrescriptionRequiredState((prevState) => !prevState);
    // await props.upload_prescription(formData,(error,success)=>{

    //   console.log("prescription_photo_formdata == ", prescription_photo);

    //   if(error){


    //     console.log("error",error);
    //   }    
    //   if(success.status == 200 ){
    //     toaster("success", success.message);
    //     handleBack();

    //   }
    //    }
    // );

  }

  const handleModalUploadPrescriptionClick = () => {
    setPrescriptionRequiredState(true);

  }
  console.log("prescriptionRequiredFileName!!!", prescriptionRequiredFile);
  console.log("prescriptionRequiredFileName@@", prescriptionRequiredFileName)
  // select prescription
  const handleModalPrescriptionSelectClick = () => {
    toaster('success', "Prescription uploaded successfully");
    // const data = new FormData();
    // data.append("prescription_name", "prescriptionRequiredFileName");
    // data.append("prescription_photo", "prescriptionRequiredFile");

    // await props.upload_prescription(data, (error, success) => {
    //   if (error) {
    //     console.log("error", error);
    //   }
    //   if (success && success.status === 200) {
    //     toaster("success", success.message);
    //   }
    // });

    setPrescriptionRequiredSelectState(!prescriptionRequiredSelectState);
  };


  const handleModalPrescriptionCloseClick = () => {
    setPrescriptionRequiredSelectState(!prescriptionRequiredSelectState);
  }

  const handleModalSelectPrescriptionClick = () => {
    setPrescriptionRequiredSelectState(true);
  }


  const prescriptionColumn = [
    {

      formatter: (cell, row) => {
        console.log("row values ==", row);
        return (
          <div className="row">
            <img
              className="avatar-img w-25 h-25 rounded-circle mr-1"
              src={(row.prescription_photo == null) ? IMG01 : `${API_URL_ROOT}/${row.prescription_photo}`}
              // src={IMG01}
              alt=""
            />
          </div>
        );
      }
    },
    {
      dataField: "prescription_name",
      text: "Name",
      formatter: (cell, row, rowIndex) => {
        console.log("row values ==", row);
        return <div> {row.prescription_name ? `${row.prescription_name}` : null}</div>;
      }
    },

  ];




  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const selectPrescriptionRow = {
    mode: 'radio',
    clickToSelect: true,
    selectionHeshowcountValueCouponaderRenderer: () => 'Select Prescription',
    headerColumnStyle: { width: '10px' },
    selectColumnPosition: 'left',
    onSelect: (row, isSelect, rowIndex, e) => {
      let precriptionFileSelectData = row.prescription_photo;
      let precriptionFileSelectDataName = row.prescription_name;
      setPrescriptionRequiredFile(precriptionFileSelectData);
      setPrescriptionRequiredFileName(precriptionFileSelectDataName)
    }
  };

  console.log("PrescriptionRequiredFile", prescriptionRequiredFile);

  console.log("cart value", cart)
  const handleRemoveCartItem = (index, totalAmount, cartItem) => {
    console.log("hi test", cartItem)




    props.delete_from_cart(cartItem, (error, success) => {
      if (error) {

        console.log("error", error);
      }
      if (success) {
        console.log("success@@@@@@", success);
        console.log("error", error);
        console.log("success", success);
        let newCartVar = [...cartVar];
        newCartVar[index] = false;
        setCartVar(newCartVar);
        console.log("newCartVar!!!!!!!!", newCartVar);
      }
    })






    { console.log("Updated cart value@@@111", props.cart.length) }
    { console.log("Updated cart value@@@", totalAmount) }

    // Get the cost of the item being removed
    const removedItemCost = parseFloat(cart[index].cost);
    { console.log("removedItemCost", removedItemCost) }


    // Subtract the cost of the removed item from the totalAmount
    if (totalAmount > 0) {
      setFinalAmount(totalAmount - removedItemCost);
    } else {
      setFinalAmount(0);
    }

    { console.log("Updated cart value!!! totalAmount@@@@:", totalAmount) }
    { console.log("Updated cart value!!! removedItemCost@@@@ :", totalAmount) }



    // Create a new array without the item at the specified index
    const updatedCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
    console.log("Updated cart value@@@", updatedCart)
    // Update the state with the new cart
    setCart(updatedCart);

    // Log the updated cart values and totalAmount

    console.log("Updated total amount:", totalAmount);
  };

  // Log the cost value for each item in the cart
  // Calculate total cost from cart array
  const totalCost = cart.reduce((total, cartItem) => total + parseFloat(cartItem.cost), 0);
  console.log("the value is in console", totalCost)
  // Log the cost for each item in the cart
  cart.forEach((cartItem, index) => {
    console.log(`Item ${index + 1}: Cost - ₹${parseFloat(cartItem.cost).toFixed(2)}`);
  });

  return (
    <div>
      <div className="content">
        <div className="container">

          <div className="row cart-row">
            <div className="col-md-12 col-lg-8  ">
              <div className="card cart-card">
                <div className="card-body">
                  <form
                    // action="bookings/booking-success/index"
                    onSubmit={formik.handleSubmit}
                  >
                    <div>
                      {/* family members 1st look*/}
                      {/*<div className="row">
                        <div className="col-md-6 col-lg-6">
                          <h4 className="card-title">Select family member</h4>

                          <Select
                            options={selectoptionsFamilyMember}
                            
                            onChange={(event) => {
                              handlePatient(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(selectoptionsFamilyMember) =>
                              selectoptionsFamilyMember["patient_name"]
                            }
                            getOptionValue={(selectoptionsFamilyMember) =>
                              selectoptionsFamilyMember["patient_id"]
                            }

                          />

                         
                          {formik.touched.patient && formik.errors.patient ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.patient}
                            </div>
                          ) : null}
                          </div>
                          <div className="col-md-6 col-lg-6">
                          <div className="mt-3 float-right">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleModalClick}
                            >
                              <span>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span>Add Family Member</span>
                            </button>
                          </div>
                        </div>
                          </div>
                          </div> */}

                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <h4 className="card-title">Select Member</h4>
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <div className="float-right">
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleModalClick}
                                  >
                                    <span>
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span>Member</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
            
                          <Select
                            options={selectoptionsFamilyMember}
                            onChange={handlePatient}
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(option) => option.patient_name}
                            getOptionValue={(option) => option.patient_id}
                            
                          />

                          {formik.touched.patient && formik.errors.patient ? (
                            <div style={{ color: 'red' }} className="mb-2">
                              {formik.errors.patient}
                            </div>
                          ) : null}
                        </div>


                        <div className="col-md-6 col-lg-6">
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <h4 className="card-title">Select Address</h4>
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <div className="float-right">
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleModalAddressClick}

                                  >
                                    <span>
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span>Address</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Select
                            options={filteredAddressOptions}
                            onChange={(event) => handleAddress(event)}
                            
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(option) => option.address || ''}
                            getOptionValue={(option) => option.address_id || ''}
                          />

                          {formik.touched.address && formik.errors.address ? (
                            <div style={{ color: 'red' }} className="mb-2">
                              {formik.errors.address}
                            </div>
                          ) : null}

                          
                        </div>




                      </div>
                    </div>


                    {/* address */}
                    {/* <div>
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <h4 className="card-title">Select Address</h4>

                          <Select
                            options={selectoptionsMyAddress}
                            
                            onChange={(event) => {
                              handleAddress(event);
                            }}
                            id="exampleFormControlInput2"
                            name="select_patient"
                            getOptionLabel={(selectoptionsMyAddress) =>
                              selectoptionsMyAddress["address"]
                            }
                            getOptionValue={(selectoptionsMyAddress) =>
                              selectoptionsMyAddress["address_id"]
                            }
                          />

                          
                          {formik.touched.address && formik.errors.address ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.address}
                            </div>
                          ) : null}
                          </div>
                          <div className="col-md-6 col-lg-6">
                          <div className="mt-3 float-right">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleModalAddressClick}
                              
                            >
                              <span>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>delete_coupon_value
                              <span>Add My Address</span>
                            </button>
                          </div>
                        </div>
                          </div>
                          </div>
                    </div> */}

                    {/* prescription upload */}

                    <div>

                      {(prescriptionArray.length > 0) ? (
                        <div className="">
                          <h4 className="card-title">Prescription</h4>
                          <div className="row prescription-row">
                            <div className="btn-group ml-3">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleModalUploadPrescriptionClick}

                              >
                                <span>
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span>Upload a prescription</span>
                              </button>
                            </div>
                            <div className="mx-2 my-auto">
                              <strong>OR</strong>
                            </div>

                            <div className="btn-group mr-3">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleModalSelectPrescriptionClick}

                              >
                                <span>
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span>Select a prescription</span>
                              </button>
                            </div>

                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="payment-widget">
                      <div>
                        <h4 className="card-title">Select Time Slot</h4>
                        <UserBooking
                          setTimeSlotUpdate={setTimeSlotUpdate}
                          setTimeSlotDayUpdate={setTimeSlotDayUpdate}

                          onChangeValue={handleTimeslot}

                        />

                        {formik.touched.timeslotId &&
                          formik.errors.timeslotId ? (
                          <div
                            style={{
                              color: "red"
                            }}
                            className="mb-2"
                          >
                            {formik.errors.timeslotId}
                          </div>
                        ) : null}
                      </div>
                      <div></div>

                      <h4 className="card-title">Payment Method</h4>
                      {(props.user_by_id?.cancelcount > 3) ? (
                        <>
                          {paymentMethodCancellApply.map((option, idxopt) => (
                            <div className="payment-list" key={idxopt}>
                              <label className="payment-radio credit-card-option">
                                <input
                                  type="radio"
                                  name="radioBtn"
                                  id={`optn[${idxopt}]`}
                                  value={option}
                                  checked={paymentMethodState === option}
                                  onChange={(event) => {
                                    handleRadioOptn(event);
                                  }}
                                />
                                <span className="checkmark"></span>
                                {option}
                              </label>
                            </div>
                          ))}
                          {formik.touched.paymentMethod &&
                            formik.errors.paymentMethod ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.paymentMethod}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {paymentMethod.map((option, idxopt) => (
                            <div className="payment-list" key={idxopt}>
                              <label className="payment-radio credit-card-option">
                                <input
                                  type="radio"
                                  name="radioBtn"
                                  id={`optn[${idxopt}]`}
                                  value={option}
                                  checked={paymentMethodState === option}
                                  onChange={(event) => {
                                    handleRadioOptn(event);
                                  }}
                                />
                                <span className="checkmark"></span>
                                {option}
                              </label>
                            </div>
                          ))}
                          {formik.touched.paymentMethod &&
                            formik.errors.paymentMethod ? (
                            <div
                              style={{
                                color: "red"
                              }}
                              className="mb-2"
                            >
                              {formik.errors.paymentMethod}
                            </div>
                          ) : null}
                        </>)
                      }


                      <div className="terms-accept col-md-6  row">
                        <div className="custom-checkbox row ">
                          <input type="checkbox"
                            value={handleTermsConditionClickState}
                            onChange={(event) => handleTermsConditionClick(event)}
                          />
                          <label className="labelstyle">
                            I have read and accept{" "}
                            <a href="/pages/terms">Terms &amp; Conditions</a>
                          </label>
                        </div>
                        {formik.touched.termsandcondition &&
                          formik.errors.termsandcondition ? (
                          <div
                            style={{
                              color: "red"
                            }}
                            className="mb-2"
                          >
                            {formik.errors.termsandcondition}
                          </div>
                        ) : null}
                      </div>
                      <Loader loading={loading} />
                      <div className="submit-section mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary submit-btn"
                          // onClick={() => handleChange()}

                          // disabled={(props.cart.length > 0) ? false : true}
                          disabled={props.cart?.length > 0 ? false : true || loading}
                        // disabled={submitButton}
                        // props.cart.length == 0 &&
                        >
                          {/* {console.log("submitButton??",submitButton)} */}
                          {/* {(paymentModeButtonState == true)?`Confirm and pay`:`Confirm`} */}
                          {loading
                            ? 'Loading...'
                            : paymentModeButtonState
                              ? 'Confirm and pay'
                              : 'Confirm'}
                          {/* Confirm and pay */}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <Modal
                  size="lg"
                  isOpen={openModal}
                  toggle={handleModalClick}
                  className="addFamilyMember"
                >
                  <ModalHeader toggle={handleModalClick} className="titleName">
                    <strong> Add Family Members </strong>
                  </ModalHeader>
                  <ModalBody>
                    <AddFamilyMember
                      {...props}
                      toggle={handleModalClick}
                      reloadFamilyMembers={reloadDataModal}
                    />
                  </ModalBody>
                </Modal>
                {/* Address */}
                <Modal
                  size="lg"
                  isOpen={openModalAddress}
                  toggle={handleModalAddressClick}
                  className="addAddress"
                >
                  <ModalHeader toggle={handleModalAddressClick} className="titleName">
                    <strong> Add Address </strong>
                  </ModalHeader>
                  <ModalBody>
                    <AddAddress
                      {...props}
                      toggle={handleModalAddressClick}
                      reloadAddress={reloadDataModalAddress}
                    />
                  </ModalBody>
                </Modal>


              </div>
            </div>

            <div className="col-md-12 col-lg-4 theiaStickySidebar">
              <div className="card booking-card">
                <div className="card-header">
                  <h4 className="card-title">Booking Summary</h4>
                </div>
                {console.log("cart length", props.cart.length)}
                {/* {(cart.length == 0) }
          {
    toaster('error', "The cart must not be empty, please add a test or package")
} */}
                {cart.length > 0 ? (
                  <>
                    {/* {props.cart.map((cartItem, idx) => (
                <div className="card-body" key={idx}>
                  <div className="booking-doc-info">
                    <Link to="#" className="booking-doc-img">
                      <img
                        src={
                          cartItem.serviceid ? testImage : packagesImage
                        }
                        alt="product"
                      />
                    </Link>
                    <div className="booking-info">
                      <h4>
                        <Link to="#">
                          {cartItem.serviceid
                            ? cartItem.name
                            : cartItem.package_name}
                        </Link>
                      </h4>
                      <h6>
                        <Link to="#">
                          {cartItem.serviceid
                            ? cartItem.short_description
                            : cartItem.short_description}
                        </Link>
                      </h6>
                      <h6>
                        <Link to="#">
                          {cartItem.serviceid
                             ? `₹ ${parseInt(cartItem.cost)}`
                             : `₹ ${parseInt(cartItem.package_cost)}`}
                        </Link>
                      </h6>


                    </div>
                  </div>
                </div>
                          ))} */}

{cart.map((cartItem, idx) => (
  <div className="card-body cart-body" key={idx}>
    <div className="booking-doc-info d-flex">
      <Link to="#" className="booking-doc-img">
        <img
          src={cartItem.serviceid ? testImage : packagesImage}
          alt="product" 
        />
      </Link>
      <div className="booking-info">
        <h4>
          <Link to="#">
            {cartItem.serviceid
              ? cartItem.name
              : cartItem.package_name}
          </Link>
        </h4>
        <h6>
          <Link to="#">
            {cartItem.serviceid
              ? cartItem.short_description
              : cartItem.short_description}
          </Link>
        </h6>
        <h6>
          <Link to="#">
            {cartItem.serviceid
               ? `₹ ${parseInt(cartItem.cost)}`
               : `₹ ${parseInt(cartItem.package_cost)}`}
          </Link>
        </h6>
      </div>
      {/* Move the remove button outside the "booking-info" div */}
      <button
        type="button"
        className="btn btn-danger btn-sm ml-auto remove_cart_button"
        onClick={() => handleRemoveCartItem(idx,finalAmount,cartItem)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
      
    </div>
    
  </div>

  
  
))}
                        {/* <button
          type="button"
             className="btn btn-danger btn-sm ml-auto"
          onClick={() => handleRemoveCartItem(idx)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button> */}
        {/* <hr style={{ border: "1px solid #ccc" , margin: "20px 0" }} /> */}
              <div className="booking-summary">
                <div className="booking-item-wrap">
                  <ul className="booking-fee mx-2">
                    <li>
                    <hr style={{ border: "none", borderTop: "1px dashed rgb(48 101 181)", margin: "10px 0", width: "100%" }} />

                    {console.log("deliveryFee",deliveryFee)}
                      {/* Delivery Fee <span>₹{deliveryFee}</span> */}
                      
                      Delivery Fee <span>₹{parseInt(deliveryFee)}</span>
                    </li>
                    {
                      (props.user_by_id?.pamount > 0) ? (
                        <li>
                          Cancellation Fee of previous order<span>₹ {props.user_by_id?.pamount}</span>
                        </li>
                      ) : null
                    }
                    {/* <li>
                            Cancellation Fee of previous order<span>₹10</span>
                          </li> */}
                          {console.log("discountValueCoupon@@@", optionValueCoupon.discount_type)}
                          {/* <li>
                      Discount Applied{" "}
                      <span>
                        ₹
                        {couponValidateState == true
                        
                          ? "00"
                          : optionValueCoupon.discount_value!== ""
                            ?optionValueCoupon.discount_value
                            : "00"}
                      </span>
                    </li> */}

                          <li>
                            Discount Applied{" "}
                            <span>
                               
                              {couponValidateState === true
                                ? "00" // If couponValidateState is true, show "00"
                                : optionValueCoupon?.discount_type === "amount"
                                  ? `${optionValueCoupon.discount_value} ₹` // Show amount if discount_type is "amount"
                                  : optionValueCoupon?.discount_type === "percentage"
                                    ? `${optionValueCoupon.discount_value} %` // Show percentage if discount_type is "percentage"
                                    : "00" // Fallback to "00" when neither is true

                              }
                            </span>
                          </li>


                          {/* New li for Discount Amount */}
  {discountAmount && (
    <li>
      Discount Amount <span>₹{parseInt(discountAmount)}</span>
    </li>
  )}

                          <li className="row  ml-3 coupon-list">
                            <input
                              type="text"
                              className="form-control "
                              id="exampleFormControlInput1"
                              placeholder="Select Coupon"
                              // disabled={disabledInput}
                              name="coupon"
                              value={
                                discountNameCoupon !== ""
                                  ? discountNameCoupon
                                  : ""
                              }
                            // onChange={(event) => onChangeCouponName(event)}
                            //  onBlur={(event) =>onBlurCouponName(event)}
                            // {...formik.getFieldProps("coupon")}
                            />
                            <span className=" selcoupon-btn">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleModalCouponClick}
                              >
                                <span>
                                  {/* <i
                                    className="fa fa-plus-circle text-white"
                                    aria-hidden="true"
                                  ></i> */}
                                 
                                </span>
                                Select Coupon
                                 
                                
                              </button>
                            </span>
                          </li>
                        </ul>

                        <div className="text-center coupon-func">

                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={validateCoupon}
                            disabled={apply_coupon}
                          >
                            {/* <span>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </span> */}

                            <span>Apply Coupon</span>
                          </button>



                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={delete_coupon_value}
                            disabled={remove_coupon}
                          >
                            <span>Remove Coupon</span>
                          </button>
                        </div>


                        {/* {couponValidateState == true ? (
                    <div
                      style={{
                        color: "red"
                      }}
                      className="my-1 text-center"
                    >
                      {couponValidateErrorMessage}
                    </div>
                  ) : null} */}
                        <div className="booking-total">
                          <ul className="booking-total-list mb-1">
                            <li>
                              {/* <span>Total</span>
                         <span className="total-cost mr-3">


                         ₹ {Math.round((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
                            props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee)  - discountValueCoupon :
                            (props.user_by_id?.pamount > 0)  ?
                              props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) :
                              (discountValueCoupon !== "") ? props.total_cart_value + parseInt(deliveryFee) - discountValueCoupon :
                                props.total_cart_value + parseInt(deliveryFee)} 
                        </span>  */}

                              <span>Total</span>
                              <span className="total-cost mr-3">


                                ₹ {
                                  remove_coupon
                                    ? Math.round(
                                      props.total_cart_value + parseInt(props.user_by_id?.pamount) + parseInt(deliveryFee)
                                    )
                                    : couponstate
                                      ? Math.round(finalAmount)
                                      : Math.round(
                                        (props.user_by_id?.pamount > 0 && discountValueCoupon !== "")
                                          ? props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon
                                          : (props.user_by_id?.pamount > 0)
                                            ? props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee)
                                            : (discountValueCoupon !== "")
                                              ? props.total_cart_value + parseInt(deliveryFee) - discountValueCoupon
                                              : props.total_cart_value + parseInt(deliveryFee)
                                      )
                                }
                              </span>
                              {console.log("total!1", props.total_cart_value)}
                              {console.log("total!3", props.user_by_id?.pamount)}
                              {console.log("total!4", (props.total_cart_value) + parseInt(props.user_by_id?.pamount) + parseInt(deliveryFee))}

















                              {/* ₹ {Math.round((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
                            props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee)  - discountValueCoupon :
                            (props.user_by_id?.pamount > 0)  ?
                              props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) :
                              (discountValueCoupon !== "") ? props.total_cart_value + parseInt(deliveryFee) - discountValueCoupon :
                                props.total_cart_value + parseInt(deliveryFee)}  */}
                              {/* </span>  */}






                              {/* {finalAmount === null || finalAmount === undefined ?  Math.round((props.user_by_id?.pamount >= 0) && (discountValueCoupon !== "")) ?
                            parseInt(totalAmount)+ props.user_by_id?.pamount + parseInt(deliveryFee)  - discountValueCoupon :
                            (props.user_by_id?.pamount > 0)  ?
                            parseInt(totalAmount) + props.user_by_id?.pamount + parseInt(deliveryFee) :
                              (discountValueCoupon !== "") ? parseInt(totalAmount)+ parseInt(deliveryFee) - discountValueCoupon :
                              parseInt(totalAmount) + parseInt(deliveryFee): finalAmount +parseInt(deliveryFee)}
                                {console.log("total value isaa", props.total_cart_value )}
                                {console.log("total value isbb",  props.user_by_id?.pamount)} */}


                              {/* <span className="total-cost mr-3">
  ₹ {Math.round(((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
    props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon :
    (props.user_by_id?.pamount > 0)  ?
      props.total_cart_value + props.user_by_id?.pamount + parseInt(deliveryFee) :
      (discountValueCoupon !== "") ? props.total_cart_value + parseInt(deliveryFee) - discountValueCoupon :
        props.total_cart_value + parseInt(deliveryFee))}
</span> */}
                              {console.log("total value is@@@t", totalAmount)}
                              {console.log("total value is@@@a", props.user_by_id?.pamount)}
                              {console.log("total value is@@@del", deliveryFee)}
                              {console.log("total value is@@@disc", discountValueCoupon)}
                              {console.log("total value is2333", props.user_by_id?.pamount)}
                              {console.log("total value is21111", cart.length)}
                              {console.log("total value is21111", cart.length)}
                              {console.log("total value is2 deliveryFee", deliveryFee)}



                              {/* <span className="total-cost mr-3">
  ₹ {Math.round(((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
    totalCost + props.user_by_id?.pamount + parseInt(deliveryFee) - discountValueCoupon :
    (props.user_by_id?.pamount > 0)  ?
    totalCost + props.user_by_id?.pamount + parseInt(deliveryFee) :
      (discountValueCoupon !== "") ? totalCost+ parseInt(deliveryFee) - discountValueCoupon :
      totalCost+ parseInt(deliveryFee))}
</span>  */}



                              {/* <span className="total-cost mr-3">
                          ₹ {((props.user_by_id?.pamount > 0) && (discountValueCoupon !== "")) ?
                           discountValueCoupon +  props.user_by_id?.pamount: props.total_cart_value + deliveryFee}
                           
                               
                        </span>  */}
                              {/* <span className="total-cost mr-3"> ₹ {discountValueCoupon}</span> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (

                  <div className="card-body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h4 style={{ margin: 0 }}>
                      <Link to="#">No items in cart</Link>
                    </h4>
                    <button
                      onClick={handleSelectTest}
                      style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '16px',
                      }}
                    >
                      Select Test
                    </button>
                  </div>
                  // <div className="card-body">
                  //   <h4>
                  //     <Link to="#">No items in cart </Link>
                  //   </h4>
                  // </div>
                )}
              </div>
              {/* coupon modal */}
              <Modal
                size="lg"
                isOpen={openModalCoupon}
                toggle={handleModalCouponClick}
                className="couponSelect"
              >
                <ModalHeader
                  toggle={handleModalCouponClick}
                  className="titleName"
                >
                  <strong>Select Discount coupon </strong>
                </ModalHeader>
                <ModalBody>


                  <div>
                    {couponShow === true ? (
                      <>
                        <Select
                          // <option value="1">Apple</option>


                          options={selectoptionsCoupon}
                          // styles={customStyles}
                          onChange={(event) => {
                            handleCoupon(event);
                          }}
                          id="exampleFormControlInput4"
                          name="select_coupon"
                          getOptionLabel={(selectoptionsCoupon) =>
                            selectoptionsCoupon["coupon_name"]
                          }
                          getOptionValue={(selectoptionsCoupon) =>
                            selectoptionsCoupon["coupon_id"]
                          }
                        />
                      </>
                    ) : null}
                  </div>
                </ModalBody>
              </Modal>
              {/* Upload Prescription */}

              <Modal
                size="lg"
                isOpen={prescriptionRequiredState}
                toggle={handleModalPrescriptionClick}
                className="addPrescription"
              >
                <ModalHeader toggle={handleModalPrescriptionClick} className="titleName">
                  <strong> Add Prescription </strong>
                </ModalHeader>
                <ModalBody>
                  <UploadPrescription
                    {...props}
                    reloadDashboard={reloadPrecriptionDataModal}



                    toggle={handleModalPrescriptionClick}
                    reloadAddress={reloadDataModalPrescription}
                  />
                </ModalBody>
              </Modal>

              {/* handleModalPrescriptionSelectClick */}

              <Modal
                size="lg"
                isOpen={prescriptionRequiredSelectState}
                toggle={handleModalPrescriptionSelectClick}
                className="addPrescription"
              >
                <ModalHeader toggle={handleModalPrescriptionCloseClick} className="titleName">
                  <strong> Select Prescription </strong>
                </ModalHeader>
                <ModalBody>
                  <div className="card card-table mb-0 mt-2">
                    <div className="card-body">
                      <div className="table-responsive">

                        <ToolkitProvider
                          bootstrap4
                          keyField='prescriptionid'
                          data={prescriptionsData}
                          columns={prescriptionColumn}
                          search
                        >

                          {
                            props => (
                              <div>
                                {/* <h6>Input something at below input field:</h6> */}
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                                <hr />
                                <BootstrapTable striped bordered hover
                                  // defaultSorted={defaultSorted}
                                  selectRow={selectPrescriptionRow}
                                  pagination={pagination}
                                  noDataIndication={() => (
                                    <div className="text-alert">
                                      <i>{noPrescriptionsData}</i>
                                    </div>
                                  )}
                                  {...props.baseProps}
                                />
                              </div>
                            )
                          }
                        </ToolkitProvider>

                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 tex-center">
                    <div className="submit-section ml-2 mr-4">
                      <button type="button" className="btn btn-primary submit-btn"
                        onClick={handleModalPrescriptionSelectClick}>Submit</button>
                    </div>

                    <div className="submit-section ml-2">
                      <button
                        type="button"
                        className="btn btn-secondary submit-btn"
                        onClick={handleModalPrescriptionCloseClick}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>


        </div >
      </div >
    </div >
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    add_to_cart: (data, callback) => dispatch(add_to_cart_request(data, callback)),
    delete_from_cart: (data, callback) => dispatch(delete_from_cart_request(data, callback)),
    get_all_family_member: (data, callback) =>
      dispatch(get_all_family_member_req(data, callback)),
    get_all_prescriptions: (data, callback) =>
      dispatch(get_all_prescriptions_req(data, callback)),

    get_all_my_address: (data, callback) =>
      dispatch(get_all_my_address_req(data, callback)),
    get_all_coupons: (data, callback) =>
      dispatch(get_all_coupons_req(data, callback)),
    get_delivery_charges: (data, callback) =>
      dispatch(get_delivery_charges_req(data, callback)),

    verify_coupon_by_id: (data, callback) =>
      dispatch(verify_coupon_by_id_request(data, callback)),

    create_order: (data, callback) =>
      dispatch(create_order_request(data, callback)),
    verify_order: (data, callback) =>
      dispatch(verify_order_request(data, callback)),

    refresh_cart: () =>
      dispatch(refresh_cart_request()),
    get_all_prescriptions: (data, callback) => dispatch(get_all_prescriptions_req(data, callback)),

    upload_prescription: (data, callback) => dispatch(upload_prescription_req(data, callback)),
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.user.status,
    error: state.user.error,

    all_family_member: state.familymember.all_family_member,
    all_my_address: state.myaddress.all_my_address,
    create_order: state.order.create_order,
    all_coupons: state.order.all_coupons,
    user_by_id: state.user.user_by_id,
    cart: state.cart.cartVal,
    total_cart_value: state.cart.total_cost,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    requesting: state.user.requesting,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCheckout);
