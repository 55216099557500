import React, { Component } from "react";
import HomeBanner from "./homebanner";
import HomePoints from "./points";
import Connectus from "./connectus";
import Location from "./location";
import Aboutus from "./about";
import Article from "./article";
// import Appointment from "./appointment";
import OurPackages from "./packages";
import OurTests from "./tests";
import Counts from "./counts";
import Testimonial from "./testimonials";
import SocialIcons from "./SocialIcons";
import PromotionalBanner from "./promotionBanner";
import WhyBookWithUs from "./whywithus";
import HealthConcerns from "./healthconcern";





// import Content from "./AboutContent";

  

class Home extends Component {
  render() {
    return (
      <div>
        <div className="main-wrapper">
       
          <HomeBanner />
         <HomePoints />
      
         <OurTests/>
         <OurPackages />
         <Connectus />
         <Location />
        {/* <Aboutus /> */}
        <PromotionalBanner />
         <Testimonial />
        <Counts/>
        <WhyBookWithUs />
        {/* <HealthConcerns /> */}
        {/* <Article /> */}
        <SocialIcons />
        {/* <SocialIcons1 /> */}
        {/* <Sidebar /> */}
     
         {/* <Content />        */}
        {/* <Appointment /> */}
       

        </div>
      </div>
    );
  }
};
export default Home;
