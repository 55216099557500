import React, { Component } from "react";
import Slider from "react-slick";
import testimonial1 from "../../../assets/images/testimonials.jpg";
import testimonial2 from "../../../assets/images/testimonial2.jpg";
import "./testimonials.css"
class Counts extends Component {
    render() {
        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false, // Enable autoplay
            autoplaySpeed: 3000, // Set autoplay interval (in milliseconds)
            responsive: [
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
            ],
        };

        return (
            <>
            <div className="testimonial-heading">
            <h1 className="text-center">Testimonials</h1>
            </div>  
            <section className="testimonial">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            <div className="testimonial-slider slider">
                                <Slider {...settings}>

                                    <div className="testimonial-item">
                                        <div className="row testimonial-row">
                                            <div className="col-12 col-lg-12 testimonial-col">

                                                <div className="testimonial-profile text-center">
                                                    <div>
                                                        <div className="profile-icon">
                                                            <img src={require('../../../assets/images/testimonial2.jpg')} alt="" />
                                                        </div>
                                                        <h4 >Bakthavatsalam</h4>
                                                        <span>Our clients</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-content text-center">
                                                    {/* <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2> */}

                                                    <p>
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '2.2em', color: '' }}>
                                                            &#8220;
                                                        </span>
                                                        Appreciate the staff for their quick response. Well maintained Diagnostics!!
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '' }}>
                                                            &#8221;
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                {/* <div className="">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="testimonial-item">
                                        <div className="row testimonial-row">
                                            <div className="col-12 col-lg-12 testimonial-col">

                                                <div className="testimonial-profile text-center">
                                                    <div>
                                                        <div className="profile-icon">
                                                            <img src={require('../../../assets/images/testimonials.jpg')} alt="" />
                                                        </div>
                                                        <h4 >Bakthavatsalam</h4>
                                                        <span>Our clients</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-content text-center">
                                                    {/* <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2> */}

                                                    <p>
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8220;
                                                        </span>
                                                        Appreciate the staff for their quick response. Well maintained Diagnostics!!
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8221;
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                {/* <div className="">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="testimonial-item">
                                        <div className="row testimonial-row">
                                            <div className="col-12 col-lg-12 testimonial-col">

                                                <div className="testimonial-profile text-center">
                                                    <div>
                                                        <div className="profile-icon">
                                                            <img src={require('../../../assets/images/testimonial2.jpg')} alt="" />
                                                        </div>
                                                        <h4 >Bakthavatsalam</h4>
                                                        <span>Our clients</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-content text-center">
                                                    {/* <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2> */}

                                                    <p>
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8220;
                                                        </span>
                                                        Appreciate the staff for their quick response. Well maintained Diagnostics!!
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8221;
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                {/* <div className="">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-item">
                                        <div className="row testimonial-row">
                                            <div className="col-12 col-lg-12 testimonial-col">

                                                <div className="testimonial-profile text-center">
                                                    <div>
                                                        <div className="profile-icon">
                                                            <img src={require('../../../assets/images/testimonials.jpg')} alt="" />
                                                        </div>
                                                        <h4 >Bakthavatsalam</h4>
                                                        <span>Our clients</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-content text-center">
                                                    {/* <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2> */}

                                                    <p>
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8220;
                                                        </span>
                                                        Appreciate the staff for their quick response. Well maintained Diagnostics!!
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8221;
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                {/* <div className="">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-item">
                                        <div className="row testimonial-row">
                                            <div className="col-12 col-lg-12 testimonial-col">

                                                <div className="testimonial-profile text-center">
                                                    <div>
                                                        <div className="profile-icon">
                                                            <img src={require('../../../assets/images/testimonial2.jpg')} alt="" />
                                                        </div>
                                                        <h4 >Bakthavatsalam</h4>
                                                        <span>Our clients</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-content text-center">
                                                    {/* <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2> */}

                                                    <p>
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8220;
                                                        </span>
                                                        Appreciate the staff for their quick response. Well maintained Diagnostics!!
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8221;
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                {/* <div className="">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-item">
                                        <div className="row testimonial-row">
                                            <div className="col-12 col-lg-12 testimonial-col">

                                                <div className="testimonial-profile text-center">
                                                    <div>
                                                        <div className="profile-icon">
                                                            <img src={require('../../../assets/images/testimonials.jpg')} alt="" />
                                                        </div>
                                                        <h4 >Bakthavatsalam</h4>
                                                        <span>Our clients</span>
                                                    </div>
                                                </div>
                                                <div className="testimonial-content text-center">
                                                    {/* <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2> */}

                                                    <p>
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8220;
                                                        </span>
                                                        Appreciate the staff for their quick response. Well maintained Diagnostics!!
                                                        <span style={{ fontFamily: "'Georgia', serif", fontSize: '1.2em', color: '#4A90E2' }}>
                                                            &#8221;
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12">
                                                {/* <div className="">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Slider>

                            </div>


                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}

export default Counts;
