import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from "react-redux";
import { get_all_faq_req } from "../../../redux-services/actions/action.faq/action.function.js";
import "./faq.css"


function Faq(props) {
  const [search, setSearch] = useState(null);
  useEffect(() => {
        window.scrollTo(0, 0);
        const { get_all_faq } =props;
        get_all_faq();
        console.log("gen_faq =====", props.all_faq);
        // window.location.reload();
    }, []);

    
  
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const searchSpace = (event) => {
        let keyword = event.target.value;
        setSearch(keyword);
    };

    return (
        <>
            {/* Breadcrumb */}
            {/* <div className={classes.breadcrumbBar}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/home">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Faqs</li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Faqs</h2>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* /Breadcrumb */}

            {/* Page Content */}
            <div className="content">
                <div className="container-fluid">
                    {/* FAQ Heading */}
                    <div className="text-center mb-4">
                        <h1 className={classes.faqHeading}>Frequently Asked Questions</h1>
                    </div>

                    {/* Search Widget */}
                    <div className={`${classes.searchWidget} cards search-widget mb-4 w-40 justify-content-center mx-auto`}>
                        <div className="card-bodys">
                            <form className="search-form">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Search Faqs ...."
                                        className="form-control"
                                        onChange={(e) => searchSpace(e)}
                                    />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-primary">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    

                    {/* FAQ List */}
                    <div className={classes.root}>
                        {props.all_faq && props.all_faq?.response.length > 0 ? (
                            props.all_faq.response.map((faq, idx) => (
                                <>
                                {/* <div className='faq-class'> */}
                                    {search == null || faq.questions.toLowerCase().includes(search.toLowerCase()) ? (
                                        
                                        <Accordion
                                            expanded={expanded === `panel${faq.faqid}`}
                                            onChange={handleChange(`panel${faq.faqid}`)}
                                            key={idx}
                                            className={classes.accordion}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                                                aria-controls={`panel${faq.faqid}bh-content`}
                                                id={`panel${faq.faqid}bh-header`}
                                                className={classes.accordionSummary}
                                            >
                                                <Typography className={classes.heading}>
                                                    {faq.questions || `FAQ`}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Typography>
                                                    {faq.answers || `FAQ ANSWER`}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : null}
                                    {/* </div> */}
                                </>
                            ))
                        ) : (
                            <div className={classes.noFaqsFound}>
                                <h6 className="text-center font-weight-bold">No FAQs found</h6>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* /Page Content */}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
      width: '100%',
      padding: '2% 10%',
      marginBottom: '2%',
      [theme.breakpoints.down('md')]: {
          padding: '2% 5%',
      },
      [theme.breakpoints.down('sm')]: {
          padding: '2% 3%',
      },
  },
  faqHeading: {
      fontSize: 'clamp(24px, 5vw, 32px)',
      fontWeight: 'bold',
      color: '#e97a24',
      marginBottom: '20px',
      textTransform: 'uppercase',
  },
  expandIcon: {
        color: '#e97a24',
    },
  searchWidget: {
      borderRadius: '8px',
      margin: '0 auto 20px',
      width: '30%',
      [theme.breakpoints.down('md')]: {
          width: '30%',
      },
      [theme.breakpoints.down('sm')]: {
          width: '70%',
      },
      '& input': {
          border: '1px solid #e0e0e0',
          borderRadius: '30px',
          padding: '12px 20px',
          fontSize: '16px',
          width: '100%',
          transition: 'all 0.3s ease',
          '&:focus': {
              borderColor: '#1e88e5',
              boxShadow: '0 0 5px rgba(30, 136, 229, 0.5)',
          },
      },
      '& button': {
          backgroundColor: '#1e3c72',
          border: 'none',
          borderRadius: '30px',
          padding: '10px 20px',
          color: '#fff',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
              backgroundColor: '#2a5298',
          },
      },
  },
  accordion: {
      margin: '7px 0',
      borderRadius: '8px',
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      '&:before': {
          display: 'none',
      },
      '&:hover': {
          transform: 'scale(1.01)',
      },
      [theme.breakpoints.down('sm')]: {
          margin: '8px 0',
      },
  },

  accordionSummary: {
      background: '#f9f9f9',
      
      borderRadius: '8px',
      padding: '0 20px',
      
      '&:hover': {
          background: '#3065b5',
      },
      [theme.breakpoints.down('sm')]: {
          padding: '0 10px',
      },
  },
  accordionDetails: {
      padding: '20px',
      background: '#f8f9fa',
      color: '#555',
      lineHeight: 1.6,
      [theme.breakpoints.down('sm')]: {
          padding: '15px',
      },
  },
  noFaqsFound: {
      padding: '30px',
      textAlign: 'center',
      background: '#fff',
      borderRadius: '8px',
      color: '#666',
      fontStyle: 'italic',
      [theme.breakpoints.down('sm')]: {
          padding: '20px',
      },
  },
}));


const mapDispatchToProps = (dispatch) => {
    return {
        get_all_faq: () => dispatch(get_all_faq_req()),
    };
};

const mapStateToProps = (state) => {
    return {
        status: state.user.status,
        error: state.user.error,
        all_faq: state.faq.all_faq,
        user: state.user.user,
        isLoggedIn: state.user.isLoggedIn,
        requesting: state.user.requesting,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
